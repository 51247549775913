@media (min-width: 1024px) {
  .bbbb {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .aaaa {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .foodMenu-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
  }

  .left-container {
    width: 20%;
    flex: 0 0 auto;
    position: sticky;
    z-index: 1;
    top: 15%;
    left: 0;
    height: 87vh;
    border-right: 1px solid #e5e5ea;
    padding-top: 0.7rem;
  }

  .left-container li {
    margin-top: 1rem;
  }

  .strong {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .category {
    cursor: pointer;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    padding-bottom: 1.5rem;
    text-decoration: none;
  }

  .category.active {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: underline;
  }

  .food-menu-category,
  .beverage-menu-category-res {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .beverage-menu-category-res.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    text-decoration: none;
  }

  .food-menu-category.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
  .FoodCategoryHeading {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 12%;
    z-index: 3;
    padding: 1rem 0rem 0.5rem 0rem;
    background-color: #fff;
    padding-left: 2rem;
  }

  .FoodCategoryHeading h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .Food-menu-item-name {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    padding-top: 0.5rem;
  }

  .Food-menu-item-desc {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015rem;
    padding-top: 0.75rem;
  }

  .food-menu-picture {
    min-width: 8rem;
    min-height: 8rem;
    max-width: 8rem;
    max-height: 8rem;
  }

  .right-container {
    width: 85%;
  }


  .FoodMenu-child {
    display: flex;
    padding: 1.9rem 0rem 1.9rem 2rem;
    gap: 0rem 1.25rem;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5ea;
  }

  .food-menu-picture-container {
    position: relative;
  }

  .button-container {
    position: absolute;
    top: 84%;
    left: 50%;
    transform: translateX(-50%);
  }

  .button-select {
    display: flex;
    width: 5.375rem;
    padding: 0.625rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(34, 34, 34, 0.08);
    /* color: #7e0000;/ */
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    /* border: 1px solid #bf8080; */
  }

  .button-selected {
    display: inline-flex;
    padding: 0.625rem 0rem;
    width: 5.375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    box-shadow: 0px 4px 8px 0px rgba(34, 34, 34, 0.08);
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .max-width {
    margin-bottom: 3rem;
  }
}
