/************************** Full screen ********************** */

@media (min-width: 1301px) {
  .mobile-call-link {
    display: none;
  }

  .imgFIXcls {
    object-fit: cover;
  }

  .offerItem {
    /* border: 2px solid red; */

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .sectionNavParent {
    display: none;
  }
  .image-grid {
    display: flex;
    width: 85%;
    height: 56vh;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .image-grid video {
    width: 65%;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .first-grid-img {
    border-radius: 0 0.5rem 0 0;
  }

  .second-grid-img {
    border-radius: 0 0 0.5rem 0;
  }

  .image-grid-inner {
    width: 34%;
    height: 100%;
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image-grid-inner > div {
    width: 100%;
    height: 49%;
  }
  .image-grid-inner > div > img {
    width: 100%;
    height: 100%;
  }

  .image-grid-inner > div:nth-child(3) {
    position: relative;
  }

  .image-grid-inner > div:nth-child(3)::before {
    content: "View more";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    opacity: 1;
    border-radius: 0 0 0.5rem 0;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    width: 80%;
    position: relative;
    z-index: 1000;
  }

  .popup > span {
    position: absolute;
    top: 5%;
    left: 8%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }

  .popup > .slide-number {
    position: absolute;
    top: 4%;
    left: 50%;
    font-family: Gilroy-Regular;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }
  /* ***************************TEXT SECTION*************************** */

  .TextDetailsSection {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .TextDetailsSection > div:nth-child(1) {
    width: 48%;
  }

  .TextDetailsSection > div:nth-child(3) {
    width: 48%;
  }

  .text-section-outlet {
    width: 85%;
    margin: 1rem auto;
  }

  .TextSectionHeading > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
  }

  .TextSectionHeading > h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > h5 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .share-div {
    display: flex;
    gap: 0.62rem;
  }

  .share-bttn {
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .phone-div {
    cursor: pointer;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .number-popup {
    position: relative;
    top: 20%;
    left: 2%;
  }

  .share-bttn:hover,
  .phone-div:hover {
    background: #f5f5f5;
  }

  .detailWithIcon {
    display: flex;
    gap: 1.25rem;
  }

  .detailWithIcon > div > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .detailWithIcon > div > p {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .divChildOne {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem 1rem 0rem;
    margin-top: 3rem;
    border-bottom: 1px solid #d8d8dc;
    border-top: 1px solid #d8d8dc;
  }

  .divChildTwo {
    padding: 1rem 1rem 3rem 0rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3rem;
  }

  .divChildTwo > div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .divChildTwo > div > div {
    display: flex;
    align-items: center;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    gap: 1rem;
  }

  .CuisineParent {
    display: flex;
  }

  .seating100,
  .floating100 {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-right: 1.2rem;
  }
  .divChildThree {
    padding: 1rem 1rem 0rem 0rem;
  }
  .divChildThree > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildThree > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0225rem;
    overflow: hidden;
    transition: height 0.1s ease;
  }

  .readMoreButton {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
  }

  .divChildThree > p.expanded {
    height: auto;
    margin-bottom: 0rem;
  }

  .divChildThree > p.collapsed {
    height: 6.2rem;
    margin-bottom: 0rem;
    overflow: hidden;

    /* border: 1px solid red; */
  }

  .divChildFour {
    padding: 0rem 1rem 2rem 0rem;
  }
  .divChildFour > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildFour > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem 0rem;
  }
  .divChildFour > div > div {
    display: flex;
    gap: 0.75rem;
    /* color: #242426;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem; */

    color: #242426;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .carouselStyle3,
  .imageStyle3 {
    display: none;
  }

  .slide-numberTwo {
    display: none;
  }
}

/* / / ***************** Laptop M *********************  / */
@media (min-width: 993px) and (max-width: 1300px) {
  .mobile-call-link {
    display: none;
  }

  .imgFIXcls {
    object-fit: cover;
  }

  .sectionNavParent {
    display: none;
  }
  .image-grid {
    display: flex;
    width: 85%;
    /* height: 56vh; */
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .image-grid video {
    width: 65%;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .first-grid-img {
    border-radius: 0 0.5rem 0 0;
  }

  .second-grid-img {
    border-radius: 0 0 0.5rem 0;
  }

  .image-grid-inner {
    width: 34%;
    /* height: 100%; */
    display: flex;
    /* gap: 0.3rem; */
    flex-direction: column;
    justify-content: space-between;
  }

  .image-grid-inner > div {
    width: 100%;
    height: 49%;
  }
  .image-grid-inner > div > img {
    width: 100%;
    height: 100%;
  }

  .image-grid-inner > div:nth-child(3) {
    position: relative;
  }

  .image-grid-inner > div:nth-child(3)::before {
    content: "View more";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    opacity: 1;
    border-radius: 0 0 0.5rem 0;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    width: 80%;
    position: relative;
    z-index: 1000;
  }

  .popup > span {
    position: absolute;
    top: 5%;
    left: 8%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }

  .popup > .slide-number {
    position: absolute;
    top: 4%;
    left: 50%;
    font-family: Gilroy-Regular;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }
  /* ***************************TEXT SECTION*************************** */

  .TextDetailsSection {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .TextDetailsSection > div:nth-child(1) {
    width: 48%;
  }

  .TextDetailsSection > div:nth-child(3) {
    width: 48%;
  }

  .text-section-outlet {
    width: 85%;
    margin: 1rem auto;
  }

  .TextSectionHeading > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
  }

  .TextSectionHeading > h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > h5 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .share-div {
    display: flex;
    gap: 0.6rem;
  }

  .share-bttn {
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .phone-div {
    cursor: pointer;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .number-popup {
    position: relative;
    top: 20%;
    left: 2%;
  }

  .share-bttn:hover,
  .phone-div:hover {
    background: #f5f5f5;
  }

  .detailWithIcon {
    display: flex;
    gap: 0.6rem;
  }

  .detailWithIcon > div > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .detailWithIcon > div > p {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .divChildOne {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem 1rem 0rem;
    margin-top: 3rem;
    border-bottom: 1px solid #d8d8dc;
    border-top: 1px solid #d8d8dc;
  }

  .divChildTwo {
    padding: 1rem 1rem 3rem 0rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3rem;
  }

  .divChildTwo > div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .divChildTwo > div > div {
    display: flex;
    align-items: center;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    gap: 1rem;
  }

  .CuisineParent {
    display: flex;
  }

  .seating100,
  .floating100 {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-right: 1.2rem;
  }
  .divChildThree {
    padding: 1rem 1rem 0rem 0rem;
  }
  .divChildThree > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildThree > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    overflow: hidden;
    transition: height 0.1s ease;
  }

  .readMoreButton {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
  }

  .divChildThree > p.expanded {
    height: auto;
    margin-bottom: 0rem;
  }

  .divChildThree > p.collapsed {
    height: 10rem;
    margin-bottom: 0rem;
    overflow: hidden;
  }

  .divChildFour {
    /* padding: 0rem 0rem 2rem 0rem; */
  }
  .divChildFour > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildFour > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem 0rem;
  }
  .divChildFour > div > div {
    display: flex;

    align-items: center;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .carouselStyle3,
  .imageStyle3 {
    display: none;
  }

  .slide-numberTwo {
    display: none;
  }
}

/* / / ***************** Laptop S *********************  / */
@media (min-width: 769px) and (max-width: 992px) {
  .mobile-call-link {
    display: none;
  }
  .sectionNavParent {
    display: none;
  }
  .imgFIXcls {
    object-fit: cover;
  }
  .image-grid {
    display: flex;
    width: 85%;
    height: 56vh;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .image-grid video {
    width: 65%;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .first-grid-img {
    border-radius: 0 0.5rem 0 0;
  }

  .second-grid-img {
    border-radius: 0 0 0.5rem 0;
  }

  .image-grid-inner {
    width: 34%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image-grid-inner > div {
    width: 100%;
    height: 49%;
  }
  .image-grid-inner > div > img {
    width: 100%;
    height: 100%;
  }

  .image-grid-inner > div:nth-child(3) {
    position: relative;
  }

  .image-grid-inner > div:nth-child(3)::before {
    content: "View more";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    opacity: 1;
    border-radius: 0 0 0.5rem 0;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    width: 80%;
    position: relative;
    z-index: 1000;
  }

  .popup > span {
    position: absolute;
    top: 5%;
    left: 8%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }

  .popup > .slide-number {
    position: absolute;
    top: 4%;
    left: 50%;
    font-family: Gilroy-Regular;
    font-style: normal;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    z-index: 1001;
  }
  /* ***************************TEXT SECTION*************************** */

  .TextDetailsSection {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .TextDetailsSection > div:nth-child(1) {
    width: 48%;
  }

  .TextDetailsSection > div:nth-child(3) {
    width: 48%;
  }

  .text-section-outlet {
    width: 85%;
    margin: 1rem auto;
  }

  .TextSectionHeading > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
  }

  .TextSectionHeading > h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > h5 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .TextSectionHeading > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .share-div {
    display: flex;
    gap: 0.6rem;
  }

  .share-bttn {
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .phone-div {
    cursor: pointer;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .number-popup {
    position: relative;
    top: 20%;
    left: 2%;
  }

  .share-bttn:hover,
  .phone-div:hover {
    background: #f5f5f5;
  }

  .detailWithIcon {
    display: flex;
    gap: 1.25rem;
  }

  .detailWithIcon > div > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .detailWithIcon > div > p {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .divChildOne {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem 1rem 0rem;
    margin-top: 3rem;
    border-bottom: 1px solid #d8d8dc;
    border-top: 1px solid #d8d8dc;
  }

  .divChildTwo {
    padding: 1rem 1rem 3rem 0rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3rem;
  }

  .divChildTwo > div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .divChildTwo > div > div {
    display: flex;
    align-items: center;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    gap: 1rem;
  }

  .CuisineParent {
    display: flex;
  }

  .seating100,
  .floating100 {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-right: 1.2rem;
  }
  .divChildThree {
    padding: 1rem 1rem 0rem 0rem;
  }
  .divChildThree > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildThree > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
    overflow: hidden;
    transition: height 0.1s ease;
  }

  .readMoreButton {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
  }

  .divChildThree > p.expanded {
    height: auto;
    margin-bottom: 0rem;
  }

  .divChildThree > p.collapsed {
    height: 10rem;
    margin-bottom: 0rem;
    overflow: hidden;
  }

  .divChildFour {
    padding: 0rem 1rem 2rem 0rem;
  }
  .divChildFour > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .divChildFour > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem 0rem;
  }
  .divChildFour > div > div {
    display: flex;
    gap: 0.75rem;
    color: #636366;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .carouselStyle3,
  .imageStyle3 {
    display: none;
  }

  .slide-numberTwo {
    display: none;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 577px) and (max-width: 768px) {
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 576px) {
  .carouselStyle3,
  .imageStyle3 {
    width: 100%;
    height: 40vh;
    margin: 0 auto;
    position: relative;
    border-radius: 0;
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carouselStyle3 .carousel-indicators,
  .image-grid,
  .popup {
    display: none;
  }

  .carouselContainer {
    position: relative;
  }

  .CuisineParent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .slide-numberTwo {
    position: absolute;
    bottom: 5%;
    right: 10%;
    color: #fff;
    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inline-flex;
    padding: 0.25rem 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    border-radius: 0.125rem;
    background: #787878;
  }

  .text-section-outlet {
    width: 90%;
    margin: 1.5rem auto;
  }
  .TextSectionHeading > h2 {
    width: 90%;
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: 0.0325rem;
  }
  .TextSectionHeading > h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01875rem;
  }
  .TextSectionHeading > h5 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01875rem;
  }
  .TextSectionHeading > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .share-div {
    display: flex;
    gap: 0.6rem;
    padding-top: 1rem;
  }

  .phone-div {
    display: block;
  }
  .mobile-call-link {
    display: none;
  }

  .share-bttn,
  .phone-div {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0.625rem 0rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01875rem;
  }

  .sectionNavParent {
    display: flex;
    gap: 1.25rem;
    padding: 1rem 0rem 0rem 5%;
    border-bottom: 0.5px solid #8e8e93;
    position: sticky;
    top: 7%;
    z-index: 10;
    background-color: #fff;
  }

  .sectionNavParent > div {
    color: #767c8f;
    font-family: Gilroy-Medium;
    padding-bottom: 1.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    cursor: pointer;
  }

  .activeSection {
    color: #4a6afe !important;
    border-bottom: 2px solid #4a6afe;
  }

  .detailWithIcon {
    display: flex;
    gap: 1.25rem;
  }

  .detailWithIcon > div > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .detailWithIcon > div > p {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .divChildOne {
    padding: 0.5rem 0rem 1.88rem 0rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .TextDetailsSection {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d8d8dc;
  }
  .TextDetailsSection > div:nth-child(1) {
    width: 95%;
  }
  .TextDetailsSection > div:nth-child(3) {
    width: 95%;
  }

  .divChildThree {
    padding: 1.88rem 1rem 1.88rem 0rem;
  }
  .divChildThree > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .divChildThree > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    margin-top: 1rem;
  }

  .divChildThree > p.expanded {
    height: auto;
  }

  .divChildThree > p.collapsed.specialEstate {
    height: 16.88rem;
    overflow: hidden;
  }

  .divChildTwo {
    padding: 1.88rem 1rem 1.88rem 0rem;
    border-bottom: 1px solid #d8d8dc;
    border-top: 1px solid #d8d8dc;
    display: flex;
    flex-direction: column;
    width: 95%;
    gap: 1.5rem;
  }
  .divChildTwo > div:nth-child(2) > div {
    display: flex;
    gap: 1rem;
  }
  .LaptopLine {
    display: none;
  }
  .divChildTwo > div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .divChildTwo > div > div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .seating100,
  .floating100 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .divChildFour {
    padding: 1.88rem 0rem;
  }

  .divChildFour > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .divChildFour > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .divChildFour > div > div {
    display: flex;
    gap: 0.3rem;
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    align-items: center;
  }

  .readMoreButton {
    display: none;
  }
}
