/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .question-card {
    min-height: 90vh;
    padding: 5rem 6.25rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .left-div {
    width: 25%;
  }

  .left-div h2 {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .left-div p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .right-div {
    width: 55%;
  }

  .outsideText {
    font-size: 1.3rem;
  }

  .insideText {
    font-size: 1rem;
    font-family: Gilroy-Regular;
  }
}
/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 640px) {
  .question-card {
    padding: 3.75rem 1.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.12rem;
  }

  .left-div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .left-div h2 {
    color: #4a6afe;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0425rem;
  }

  .left-div p {
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .right-div {
    width: 100%;
  }

  .outsideText {
    font-size: 1rem;
  }

  .insideText {
    font-size: 0.7rem;
  }
}
