/************************** Laptop L ********************** */
@media (min-width: 1301px) {
  .package {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .package h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .package-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .package-buttons button {
    display: flex;
    height: 2.8rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    border: 1px solid #aeaeb2;
    color: #000;
    background: #fff;
  }

  .package-buttons button:focus,
  .package-buttons button.focused {
    background: #4a6afe;
    color: #fff;
    border: 1px solid #4a6afe;
  }

  /************** four cardssssss  ****************/

  .package-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 1.25rem;
    width: 100%;
    margin-top: 2rem;
  }

  .card1:hover {
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.12);
  }

  .card1 {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    width: 100%;
    min-height: 42vh;
  }

  .img-card {
    width: 100%;
    height: 0.6rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .inner-card {
    display: flex;
    padding: 1rem 0rem;
    padding-bottom: 0.3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    border-bottom: 1px dashed #d8d8dc;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .regular {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .choose {
    width: 18%;
  }

  .inside-regular,
  .inside-regular3 {
    height: 1.8rem;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .inside-regular2 {
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    border-radius: 0.25rem;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    background: #fcddd6;
  }

  .non-alcoholic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .non-alcoholic h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .non-alcoholic h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    margin: 0;
    padding: 0;
  }

  .non-alcoholic span:nth-child(1) {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration: line-through;
  }

  .non-alcoholic h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .regular-food {
    display: flex;
    gap: 0.75rem;
    width: 97%;
  }

  .food-card {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }

  .whatIncludes {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .whatIncludes ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.7rem;
    width: 70%;
    justify-content: space-between;
  }

  .whatIncludes ul li {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    list-style: disc;
    width: 45%;
  }

  .icon-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #000 !important;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    padding-left: 1.5rem;
  }

  .choose-bttn {
    width: 5.625rem;
    padding: 0.5rem 1rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #7e0000;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inside-regular-parent {
    display: flex;
    gap: 1rem;
  }
}

/* / / ***************** Laptop M *********************  / */
@media (min-width: 993px) and (max-width: 1300px) {
  .package {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .package h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .package-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .package-buttons button {
    display: flex;
    height: 2.8rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    border: 1px solid #aeaeb2;
    color: #000;
    background: #fff;
  }

  .package-buttons button:focus,
  .package-buttons button.focused {
    background: #4a6afe;
    color: #fff;
    border: 1px solid #4a6afe;
  }

  /************** four cardssssss  ****************/

  .package-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 1.25rem;
    width: 100%;
    padding-top: 1.5rem;
  }

  .card1:hover {
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.12);
  }

  .card1 {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    width: 100%;
    min-height: 42vh;
  }

  .img-card {
    width: 100%;
    height: 0.6rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .inner-card {
    display: flex;
    padding: 1rem 0rem;
    padding-bottom: 0.3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    border-bottom: 1px dashed #d8d8dc;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .regular {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .choose {
    width: 18%;
  }

  .inside-regular,
  .inside-regular3 {
    height: 1.8rem;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .inside-regular2 {
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    border-radius: 0.25rem;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    background: #fcddd6;
  }

  .non-alcoholic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .non-alcoholic h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .non-alcoholic h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    margin: 0;
    padding: 0;
  }

  .non-alcoholic span:nth-child(1) {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration: line-through;
  }

  .non-alcoholic h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .regular-food {
    display: flex;
    gap: 0.75rem;
    width: 97%;
  }

  .food-card {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }

  .whatIncludes {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .whatIncludes ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.45rem;
    width: 100%;
    justify-content: space-between;
  }

  .whatIncludes ul li {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    list-style: disc;
    width: 45%;
  }

  .icon-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #000 !important;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    padding-left: 1.5rem;
  }

  .choose-bttn {
    width: 5.625rem;
    padding: 0.5rem 1rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inside-regular-parent {
    display: flex;
    gap: 1rem;
  }
}

/* / / ***************** Laptop S *********************  / */
@media (min-width: 769px) and (max-width: 992px) {
  .package {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85%;
    margin: 0 auto;
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .package h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .package-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .package-buttons button {
    display: flex;
    height: 2.8rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    border: 1px solid #aeaeb2;
    color: #000;
    background: #fff;
  }

  .package-buttons button:focus,
  .package-buttons button.focused {
    background: #4a6afe;
    color: #fff;
    border: 1px solid #4a6afe;
  }

  /************** four cardssssss  ****************/

  .package-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 1.25rem;
    width: 100%;
    padding-top: 1.5rem;
  }

  .card1:hover {
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.12);
  }

  .card1 {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    width: 100%;
    min-height: 42vh;
  }

  .img-card {
    width: 100%;
    height: 0.6rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .inner-card {
    display: flex;
    padding: 1rem 0rem;
    padding-bottom: 0.3rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    border-bottom: 1px dashed #d8d8dc;
    width: 92%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .regular {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .choose {
    width: 18%;
  }

  .inside-regular,
  .inside-regular3 {
    height: 1.8rem;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .inside-regular2 {
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    border-radius: 0.25rem;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    background: #fcddd6;
  }

  .non-alcoholic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }

  .non-alcoholic h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .non-alcoholic h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    margin: 0;
    padding: 0;
  }

  .non-alcoholic span:nth-child(1) {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration: line-through;
  }

  .non-alcoholic h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .regular-food {
    display: flex;
    gap: 0.75rem;
    width: 97%;
  }

  .food-card {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }

  .whatIncludes {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .whatIncludes ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.45rem;
    width: 100%;
    justify-content: space-between;
  }

  .whatIncludes ul li {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    list-style: disc;
    width: 45%;
  }

  .icon-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #000 !important;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    padding-left: 1.5rem;
  }

  .choose-bttn {
    width: 5.625rem;
    padding: 0.5rem 1rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inside-regular-parent {
    display: flex;
    gap: 1rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 577px) and (max-width: 768px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 576px) {
  .package {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 2.5rem;
    padding-top: 0.5rem;
    border-bottom: 1px solid #d8d8dc;
  }

  .package h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .package-buttons {
    display: flex;
    gap: 0.75rem;
    padding-bottom: 0.5rem;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  .package-buttons button {
    display: flex;
    padding: 0.8rem 0.78rem;

    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    border-radius: 6.25rem;
    border: 1px solid #aeaeb2;
    background: #fff;

    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .package-buttons button:focus,
  .package-buttons button.focused {
    background: #4a6afe;
    color: #fff;
    border: 1px solid #4a6afe;
  }

  .package-cards {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
  }

  .card1 {
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    width: 100%;
  }

  .card1 img {
    width: 100%;
    height: 0.625rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }

  .inner-card {
    display: flex;
    padding: 1.25rem 0rem 1rem 0rem;
    flex-direction: column;
    border-bottom: 1px dashed #d8d8dc;
    width: 90%;
    margin: 0 auto;
  }

  .regular {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .inside-regular,
  .inside-regular3 {
    padding: 0.375rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
  }

  .inside-regular-parent {
    display: flex;
    gap: 1rem;
  }

  .inside-regular2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    gap: 0.25rem;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0.25rem;
    background: #fcddd6;
  }

  .regular-icons {
    display: flex;
    gap: 0.2rem;
  }

  .non-alcoholic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0.75rem;
  }

  .non-alcoholic h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .non-alcoholic h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
    margin: 0;
    padding: 0;
  }

  .non-alcoholic span {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .non-alcoholic span:nth-child(1) {
    text-decoration: line-through;
  }
  .non-alcoholic h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .regular-food {
    display: flex;
    gap: 0.75rem;
    width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
  }

  .food-card {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 0.375rem;
    width: 100%;
  }

  .food-card h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .whatIncludes {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .whatIncludes ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.45rem;
    width: 100%;
    justify-content: space-between;
    padding: 0rem 0.5rem;
  }

  .whatIncludes ul li {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    list-style: disc;
    width: 45%;
  }

  .choose-bttn {
    display: none;
  }

  .img-card {
    width: 100%;
    height: 0.6rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
  }
}
