.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 200;
}

.modal-content {
  background: white;
  border-radius: 8px 8px 0 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.timeLabel {
  color: #242426;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.timeBoxLabel {
  display: flex;
  padding: 1rem 1.2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #d8d8dc;
  background: #fff;
}

.time-slots {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.125rem;
  padding: 1rem;
  cursor: pointer;
}

.time-slot {
  background: #f0f0f0;
  border-radius: 4px;
}

.time-slot:hover {
  background: #e0e0e0;
}

/* Disable scroll */
.no-scroll {
  overflow: hidden;
}
