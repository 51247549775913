/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .NavbarText {
    display: none;
  }

  .backParent {
    display: none;
  }

  .navbar-div {
    position: sticky;
    top: 0;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.15);
    backdrop-filter: blur(50px);
    padding: 0.9375rem 8.5rem;
    align-items: center;
    z-index: 100;
    border: none;
  }
  .navbar-div2 {
    position: sticky;
    top: 0;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    /* background: #fff; */
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.15);
    backdrop-filter: blur(50px);
    padding: 0.9375rem 7rem;
    align-items: center;
    z-index: 100;
  }

  .navbar-div .barbaar-logo1 {
    width: 8rem;
    height: 4.3rem;
  }
  .barbaar-logo3,
  .barbaar-logo2 {
    width: 4.2rem;
    height: 3.5rem;
    margin-right: 9rem;
  }

  .reserveNow {
    width: 8.5rem;
    height: 3rem;
    position: relative;
    border-radius: 6.25rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background-color: #fff;
    outline: none;
    border: 1px solid #4a6afe;
    overflow: hidden;
    background: #4a6afe;
    /* transition: color 0.8s 0.1s ease-out, border 0.8s 0.1s ease-out,
      transform 0.8s 0.1s ease-out;
    transition: color 0.8s 0.1s ease-in, border 0.8s 0.1s ease-in,
      transform 0.8s 0.1s ease-in; */
  }

  .reserveNow:hover {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    transform: scale(1);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      ),
      #3b55cb;
    /* transition: color 0.8s 0.1s ease-in, border 0.8s 0.1s ease-in,
      transform 0.8s 0.1s ease-in; */
  }
  /* 
  .reserveNow::before {
    position: absolute;
    top: 1.8em;
    left: 0;
    right: -6.5em;
    bottom: 0;
    margin: auto;
    content: "";
    border-radius: 50%;
    display: block;
    width: 20em;
    height: 20em;
    left: -5em;
    text-align: center;
    transition: box-shadow 0.5s ease-out;
    transition: box-shadow 0.5s ease-in;
    z-index: -1;
  }

  .reserveNow:hover::before {
    box-shadow: inset -1em -1em 0 10em #7e0000;
  }
  .reserveNow:hover::after {
    box-shadow: inset -1em -1em 0 10em #7e0000;
  } */

  .backArrowDiv {
    display: none;
  }

  .buttondivnav {
    display: flex;
    gap: 0rem 1.75rem;
  }
  .reserveNowIndividual {
    display: inline-flex;
    width: 8.5rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.5rem;
    /* color: #7e0000; */
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .reserveNowIndividual:hover {
    background: #f2e6e6;
  }

  .food-popup-background-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .food-popup-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .popup-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-wrapper h3 {
    margin-top: 1rem;
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    letter-spacing: 0.0275rem;
  }
  .NavbarHam,
  .NavbarHam2,
  .NavbarHam3 {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 1.125rem;
    border-radius: 6.25rem;
    border: 1px solid #d8d8dc;
    outline: none !important;
  }


  .NavbarHam > div:nth-child(2) {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #000;
  }

  .NavbarHam2 > div:nth-child(2) {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: white;
  }

  .NavbarHam3 > div:nth-child(2) {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: black;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 640px) {
  .barbaar-logo2,
  .barbaar-logo3 {
    display: none;
  }
  .navbar {
    background-color: white;
  }

  .NavbarText {
    color: black;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 1rem;
  }

  .reserveNowIndividual,
  .barbaar-logo1,
  .barbaar-logo3 {
    width: 8rem;
    height: 4.3rem;
  }

  .backArrowDiv {
    display: block;
  }

  .navbar-div2 {
    position: sticky;
    top: 0;
    height: 7vh;
    display: flex;
    padding: 0.9rem 1.4rem 0.9rem 0.6rem;
    z-index: 100;
    justify-content: space-between;
    width: 100%;
    background: #fff;
  }

  .navbar-div {
    position: sticky;
    top: 0;
    height: 10vh;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.15);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    padding: 0.9375rem 8.5rem;
    background: #fff;
    align-items: center;
    z-index: 100;
  }

  .navbar-div2 > div:nth-child(1) {
    display: none;
  }
  .backArrowDiv {
    width: 10%;
    padding-left: 1rem;
  }
  .reserveNow {
    display: none;
  }

  /* .MobileTExtParent {
    margin-right: 2.5rem;
  } */

  .NavbarHam {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 1.125rem;
    border-radius: 6.25rem;
    border: 1px solid #d8d8dc;
  }
  .NavbarHam > div:nth-child(2) {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #000;
  }

}
