@media (min-width: 10px) and (max-width: 640px){
.modal-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end; 
    z-index: 200;
  }

  .modal-content2 {
    background: white;
    padding: 1rem;
    border-radius: 8px 8px 0 0; 
    width: 100%; 
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

 .datePickerForMobile .react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
  width: 100%;
}

.datePickerForMobile .react-datepicker__month-container {
  width: 100%;
  padding: 1rem;
}

.datePickerForMobile .react-datepicker__navigation .react-datepicker__navigation--next{
  color: black;
}

.custom-calendar .react-datepicker__day, .react-datepicker__day--keyboard-selected, .custom-day {
  border-radius: 6.25rem;
  color: #000;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* ///////////////////////////////////////////////////////////////////////////////////////// */


.datePickerForMobile .react-datepicker__navigation--next {
  position: absolute;
  top: 1.1rem;
  left: 85%;
  color: #222;
}
.datePickerForMobile .react-datepicker__navigation--previous {
  position: absolute;
  top: 1.1rem;
  right: 85%;
  color: #222;
}

.datePickerForMobile .react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
  content: none;
}

.datePickerForMobile .react-datepicker__header {
  background: #fff;
  border-bottom: none;
}

.datePickerForMobile .custom-header-month-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datePickerForMobile .custom-current-month {
  color: #222;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0225rem;
}

.datePickerForMobile .react-datepicker__day-names {
  display: none;
}

.datePickerForMobile .custom-day-names {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0.7rem 0.5rem 1rem;
}

.datePickerForMobile .react-datepicker__month {
  width: 100%;
}
.datePickerForMobile .custom-day-name {
  color: #7c7c80;
  text-align: center;
  font-family: Gilroy-SemiBold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.datePickerForMobile .react-datepicker__week {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.datePickerForMobile .react-datepicker__day,
.react-datepicker__day--keyboard-selected,
.custom-day {
  border-radius: 6.25rem;
  color: #000;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.datePickerForMobile .react-datepicker__day--today {
  border: 2px solid #4a6afe !important;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
}

.datePickerForMobile .react-datepicker__day--disabled {
  border-radius: 6.25rem;
  text-decoration-line: line-through;
  color: #d1d5db;
  text-align: center;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.25rem 0.5rem;
  line-height: 1.25rem;
}
.datePickerForMobile .react-datepicker__day--disabled:hover {
  color: #d1d5db !important;
  border: none !important;
}
.datePickerForMobile .react-datepicker__day--selected {
  border-radius: 6.25rem;
  background: #4a6afe;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  padding: 0.25rem 0.5rem;
}

.datePickerForMobile .react-datepicker__day:hover {
  border: 2px solid #4a6afe;
  text-align: center;
  background-color: #fff;
  color: #000;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
}

.react-datepicker__triangle {
  display: none;
}
/* Styles/DateModal.css */
.no-scroll {
  overflow: hidden;
}


}