/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .body-div {
    min-height: 86vh;
    width: 100%;
    display: flex;
    padding: 3rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: black;
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 400;
  }

  .arrowimg {
    width: 2.25rem;
    height: 2.25rem;
  }

  .learn {
    display: flex;
    width: 100%;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
  }

  .body-div h1 {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 45%;
    font-family: Gilroy-Bold;
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
  }

  .body-div span {
    color: #4a6afe;
    font-size: 2.5rem;
    line-height: normal;
    font-family: Gilroy-Bold;
  }

  .cards-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }

  .inside-card {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 40%;
  }

  .image-card {
    width: 50%;
  }

  .image-card img {
    width: 100%;
    height: 70vh;
  }

  .inside-card h2 {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inside-card p {
    color: #d8d8dc;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    letter-spacing: 0.0225rem;
  }

  .learn h3 {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    margin-top: 1rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 640px) {
  .body-div {
    min-height: fit-content;
    width: 100%;
    display: flex;
    padding: 3.75rem 2rem;
    flex-direction: column;
    align-items: center;
    background-color: black;
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 400;
  }

  .body-div h1 {
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: Gilroy-Bold;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0425rem;
  }

  .body-div span {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0425rem;
  }

  .cards-div {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    margin: 0 auto;
  }

  .image-card {
    padding-top: 3.12rem;
    width: 100%;
  }

  .image-card img {
    width: 100%;
  }
  .outer-card {
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 0 0 100%;
    scroll-snap-align: start;
  }

  .inside-card {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 2rem;
  }

  .inside-card h2 {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .learn{
    display: flex;
    gap: 0.87rem;
    align-items: center !important;
  }

  .inside-card p {
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inside-card h3 {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02rem;
    padding-top: 0.3rem;
  }
  .arrowimg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
