/******************** Fullscreen view ************ */
@media (min-width: 1024px) {
  .BookingHistoryWrapper {
    padding: 2rem 0;
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 78vh;
  }

  .UpcomingResChildPhone {
    display: none;
  }

  .placeImg {
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    gap: 2.4375rem;
  }

  .placeImgMobile {
    display: none;
  }

  .outerHead {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .BookingsHeading {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .UpcomingResHeading,
  .PastResHeading {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.37rem;
  }
  .UpcomingResChild,
  .PastResChild {
    display: flex;
    padding: 1.5625rem;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 1rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(74, 74, 74, 0.1);
  }

  .UpcomingResChild,
  .PastResChild {
    width: 100%;
    display: flex;
    border-radius: 1rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    padding: 1.38rem;
    gap: 2rem;
    position: relative;
  }
  .UpcomingresImgOne,
  .PastresImgOne {
    /* width: 20%; */
    width: 11rem;
    height: 11rem;
    border-radius: 0.5rem;
  }
  .upcomingResInner,
  .PastResInner {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .PastResWrapper,
  .UpcomingResWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .upcomingButtons {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .upcomingButtons button {
    border-radius: 6.25rem;
    border: 1px solid #aeaeb2;
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 10%;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .UpcomingSummaryWrapper,
  .PastSummaryWrapper {
    display: flex;
    flex-direction: column;
  }
  .UpcomingSummaryChild > .UpcomingSumaryAdress,
  .PastSummaryChild > .PastSumaryAdress {
    display: flex;
    flex-direction: column;
  }
  .UpcomingSummaryChild > .UpcomingSumaryAdress > div:nth-child(1),
  .PastSummaryChild > .PastSumaryAdress > div:nth-child(1) {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .UpcomingSummaryChild > .UpcomingSumaryAdress > div:nth-child(2),
  .PastSummaryChild > .PastSumaryAdress > div:nth-child(2) {
    color: #545456;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .UpcomingSummaryChild > div,
  .PastSummaryChild > div {
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .perPersonSpan {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  .ResIDlastDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ResIDlastDiv > :nth-child(1) {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ResIDlastDiv > :nth-child(2) {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .invoicebttn {
    border-radius: 0.5rem;
    border: 1px solid #4A6AFE;
    display: flex;
    padding: 0.8rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: #4A6AFE;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .detailsbttn {
    border-radius: 0.5rem;
    background: #4A6AFE;
    display: flex;
    padding: 0.8rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .BookingHistoryWrapper {
    padding: 1.3rem 1.2rem 3rem 1.2rem;
    min-height: 85vh;
  }

  .BookingsHeading {
    display: none;
  }

  .UpcomingResHeading,
  .PastResHeading {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .PastResWrapper {
    margin-top: 2rem;
  }

  .UpcomingResChild {
    display: none;
  }

  .UpcomingResWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
  }

  .UpcomingResChildPhone {
    border-radius: 0.75rem;
    border: 1px solid #d8d8dc;

    display: flex;
    padding-bottom: 1.25rem;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  .upperMobileSummary {
    display: flex;
    gap: 0.75rem;
    /* align-items: center; */
    background: #f8f9fd;
    width: 100%;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 1.25rem;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }

  .UpcomingresImgOne {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 0.25rem;
  }

  .outletName {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .outletAddress {
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.0175rem;
  }

  .upcomingResInner {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    width: 100%;
  }

  .resDate,
  .resTime {
    color: #545456;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .resStatus {
    font-family: Gilroy-SemiBold;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .outerHead {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .UpcomingresImgOne {
    width: 4rem;
    height: 4rem;
    border-radius: 0.25rem;
  }

  .upcomingButtons {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: flex-start;
  }

  .upcomingButtons button {
    border-radius: 6.25rem;
    border: 1px solid #aeaeb2;
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 30%;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .mobilebttn {
    margin-top: 0.88rem;
    display: flex;
    gap: 0.88rem;
    align-items: center;
    justify-content: center;
  }

  .invoicebttn {
    border-radius: 0.5rem;
    border: 1px solid #4A6AFE;
    display: flex;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: #4A6AFE;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .detailsbttn {
    border-radius: 0.5rem;
    background: #4A6AFE;
    display: flex;
    padding: 0.8rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .placeImg {
    display: none;
  }
  .placeImgMobile {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    gap: 2.4375rem;
  }
}
