@media (min-width: 1024px) {
  .curved-line {
    display: none;
  }

  .PackageInfoPhone {
    display: none;
  }
  .PackageInfoMain2 {
    display: flex;
    width: 82%;
    margin: 2rem auto;
    /* margin-bottom: 4rem; */
    justify-content: space-between;
  }
  .PackageInfoInnerLeft2 {
    width: 50%;
  }
  .packageNameDiv2 {
    display: flex;
    gap: 1rem;
  }
  .error-message {
    color: red;
    padding: 0.4rem 0.8rem;
  }
  .inside-nonAlcoholic {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .inside-nonAlcoholic > div {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .book-bttn {
    display: none;
  }

  .PackageDeatailsHeaderr {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-top: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-top: 1px solid #ccc;
  }

  .packageNameDiv2 > div:nth-child(1) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }

  .packageNameDiv2 > div:nth-child(2) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #fcddd6;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageLeftOne2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-bottom: 3rem; */
    /* border: 2px solid red; */
    width: 100%;
  }
  .packageLeftOne2 > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0275rem;
    padding-top: 0.4rem;
  }
  .packageLeftOne2 > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .ItemDetailName3 {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  /* / ///////////////////////////////////// / */

  .PackageInfoInnerRightDT {
    position: sticky;
    top: 7rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.06),
      0px -4px 16px 0px rgba(34, 34, 34, 0.06);
    width: 42%;
    height: fit-content;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }

  .PackageInfoInnerRightDT h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .PackageInfoInnerRightDT > .guest-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* height: 10rem; */

    /* border: 2px solid green; */
  }

  .date-time-wrapper {
    display: flex;
    justify-content: space-between;
    height: 4rem;
  }
  .date-time-wrapper > .date-div,
  .date-time-wrapper > .slot-div {
    width: 49.99%;
  }

  .custom-dropdown {
    width: 100%;
  }
  .dropdown-header {
    display: flex;
    padding: 1rem;
    align-items: center;
    height: 4rem;
    border-radius: 0rem 0.5rem 0rem 0rem;
    border: 1px solid #ccc;
    border-bottom: none;
  }
  .dropdown-header.active {
    border: 2px solid #000;
  }
  .dropdown-header > div {
    width: 100%;
  }
  .Time-placeHolder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Time-placeHolder > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .selectedPeopleInput::placeholder,
  .Selected-place {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01375rem;
  }
  .selectedPeopleInput {
    height: 4rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem !important;
    margin-bottom: 1.2rem;
  }
  .Selected-place {
    position: absolute;
    left: 2.5rem;
    top: 8.25rem;
  }
  .Time-placeholder-child {
    color: #222;
    font-family: Gilroy-Bold;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01375rem;
  }
  .Time-Value {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .dropdown-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem 1.5rem;
    border: none;
    /* width: 96%; */
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
    position: absolute;
    right: 0.8rem;
    z-index: 1;
    gap: 0.88rem;
  }

  .dropdown-option {
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    white-space: nowrap;
  }

  .PackageInfoInnerRightDT > .date-div h2,
  .PackageInfoInnerRightDT > .slot-div h2,
  .PackageInfoInnerRightDT > .guest-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .selected-timeSlot {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 0.1rem;
    padding-left: 0.5rem;
    width: 80%;
  }

  .time-slot-header {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .selected-timeSlot > div {
    display: flex;
    width: 90%;
    padding: 0.525rem 0.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  .PackageInfoInnerRightDT > .guest-div > div,
  .selectedPeopleInput {
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.2rem;
    border: 1px solid #c4c4c4;
    background: #fff;
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .guest-div {
    border-radius: 0rem 0rem 0rem 0.5rem;
  }
  .closure-div {
    width: 100%;
  }

  .slider-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem;
  }

  .slider-input:focus + .selected-people,
  .slider-input:active + .selected-people,
  .slider-input:focus + .total-cost,
  .slider-input:active + .total-cost {
    opacity: 1;
  }
  .selected-timeSlot > div.selected {
    background-color: #4a6afe;
    color: #fff;
  }
  .PackageInfoInnerRightDT > .date-div {
    width: 100%;
  }

  .priceParentDiv,
  .priceParentDiv2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .priceParentDiv > div,
  .priceParentDiv2 > div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  .priceParentDiv .DiscountedPrice {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: line-through;
  }
  .priceParentDiv2 .ActualPrice {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .priceParentDiv .ActualPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .priceParentDiv2 .personXpax {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .priceParentDiv .personXpax {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .personXpax > span {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .TotalPrice4 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-parent2,
  .total-cost-parent3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;
  }

  .total-cost-text3,
  .total-cost3 {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .total-cost-text2 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-text2 > span {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .total-cost2 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .ReserveButton {
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    width: 100%;
    padding: 0.81rem 0rem;
    margin-top: 0.4rem;
  }
  .ReserveButton:hover {
    background: #650000;
  }

  .slider-wrapper {
    width: 100%;
    position: relative;
    margin: 1.5rem 0rem 0rem 0rem;
  }

  .slider-markers {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -5px;
  }

  .slider-markers2 {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 1.25rem;
  }

  .slider-marker {
    position: absolute;
    transform: translateX(-50%);
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .slider-marker2 {
    width: 0.1875rem;
    height: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.125rem;
    background: #0075ff;
    position: absolute;
    transform: translateX(-50%);
  }

  .moneySavedDiv {
    width: 100%;
    border-radius: 0.5rem;
    background: #dbe1ff;
    text-align: center;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    margin-top: 1.5rem;
  }

  .moneySavedDiv2 {
    width: 100%;
    border-radius: 0.5rem;
    background: #f2e6e6;
    padding: 0.75rem;
    text-align: center;
    /* color: #7e0000; */
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    /* margin-top: 0.6rem; */
  }
  .ReserveButtondiv {
    width: 100%;
    display: flex;
  }

  .ReserveButtondiv > h4 {
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }
  .ReserveButtondiv span {
    color: var(--Black, #4a6afe);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selectedPeopleInput {
    -moz-appearance: textfield;
  }

  .selectedPeopleInput::-webkit-inner-spin-button,
  .selectedPeopleInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .PackageInDetailChildHeadingNew {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    margin-top: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0225rem;

    border: 1px solid red;
  }

  .PackageInDetailWrapper p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .PackageInDetailWrapper ul {
    list-style-type: disc;
  }

  .PackageInDetailWrapper li {
    list-style-type: disc;
  }
  .PackageInDetailWrapper li {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .PackageInDetailChildParaNew {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    margin-top: 0.5rem;
  }

  .animate {
    animation: rollUpDown 1.6s ease-in-out infinite alternate;
  }

  @keyframes rollUpDown {
    0%,
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .PackageInfoPhone {
    display: none;
  }

  .curved-line {
    /* border: 1px solid red; */
    width: 100%;
  }

  /* .footer-div {
    display: none;
  } */
  .PackageInfoMain2 {
    width: 90%;
    margin: 2rem auto 2rem auto;
  }
  .PackageInfoInnerLeft2 {
    width: 96%;
  }
  .packageNameDiv2 {
    display: flex;
    gap: 1rem;
  }
  .error-message {
    color: red;
    padding: 0.4rem 0.8rem;
  }
  .inside-nonAlcoholic {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .inside-nonAlcoholic > div {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .PackageDeatailsHeaderr {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-top: 3rem;
  }

  .packageNameDiv2 > div:nth-child(1) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }

  .packageNameDiv2 > div:nth-child(2) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #fcddd6;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageLeftOne2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .packageLeftOne2 > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    align-items: center;
  }
  .packageLeftOne2 > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .ItemDetailName3 {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .PackageInDetailChildHeadingNew {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1.25rem;
  }

  .PackageInDetailWrapper p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .PackageInDetailWrapper ul {
    list-style-type: disc;
  }

  .PackageInDetailWrapper li {
    list-style-type: disc;
  }
  .PackageInDetailWrapper li {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .PackageInDetailChildParaNew {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    margin-top: 0.5rem;
  }

  .book-bttn {
    position: sticky;
    bottom: 0;
    z-index: 999;
    display: flex;
    padding: 0.625rem 0.6rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-top: 1px solid #d8d8dc;
    background: #fff;
    width: 100%;
    position: sticky;
  }

  .book-bttn button {
    width: 100%;
    padding: 1.125rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  /* / ///////////////////////////////////// / */

  /* ///right side div */

  .PackageInfoInnerRightDT {
    display: none;
  }

  .selectedPeopleInput::-webkit-inner-spin-button,
  .selectedPeopleInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .animate {
    animation: rollUpDown 1.6s ease-in-out infinite alternate;
  }

  @keyframes rollUpDown {
    0%,
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
