@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../gilroy//Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-Regular';
    src: url('../gilroy//Gilroy-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }


  @font-face {
    font-family: 'Gilroy-Thin';
    src: url('../gilroy//Gilroy-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  /* Gilroy SemiBold */
  @font-face {
    font-family: 'Gilroy-Medium';
    src: url('../gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../gilroy/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  /* Gilroy Bold */
  @font-face {
    font-family: 'Gilroy-Bold';
    src: url('../gilroy//Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  /* Gilroy Medium */
  @font-face {
    font-family: 'Gilroy-Medium';
    src: url('../gilroy//Gilroy-Medium.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('../gilroy//Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: Gilroy-SemiBold; */
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

html, body {
  margin: 0;
  padding: 0;
}

/* @media (min-width: 10px) and (max-width: 640px) {
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
} */
