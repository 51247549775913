/* web css */
@media (min-width: 1024px) {
  .RequestWrapper {
    width: 85%;
    margin: 0 auto;
    padding-bottom: 4rem;
    display: flex;
    /* gap: 3.5rem; */
    justify-content: space-between;
    padding-top: 3rem;
    min-height: 77vh;
  }

  .desktopMenuParent input[type="checkbox"],
  .desktopMenuParent input[type="radio"],
  .fandbmenuMobile2 input[type="checkbox"],
  .fandbmenuMobile2 input[type="radio"] {
    appearance: none;
    position: relative;
    cursor: pointer;
  }

  .desktopMenuParent input[type="checkbox"]:checked:after,
  .desktopMenuParent input[type="radio"]:checked:after,
  .fandbmenuMobile2 input[type="checkbox"]:checked:after,
  .fandbmenuMobile2 input[type="radio"]:checked:after {
    content: "";
    position: absolute;
    border-radius: 0.125rem;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    top: 3px;
    left: 6.4px;
  }

  .ResIDTop {
    display: flex;
    width: 55%;
    justify-content: space-between;
  }

  .hostheading {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .subheadingInvite {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .navbar-div2 {
    display: flex;
  }
  .barbaar-logo {
    width: 5.4rem;
    height: 5rem;
  }
  .inner-navdiv2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cardMobile,
  .fandbmenuMobile {
    display: none;
  }

  /* .fandbmenuMobile {
    display: block;
  } */

  .outer-new-individual {
    display: flex;
    gap: 7rem;
  }

  .CustomerForMerchantDeatil {
    display: flex;
    gap: 1rem;
  }

  .CustomerForMerchantWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  /* *************LEFT SIDE***************** */

  .RequestWrapperLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .RequestSuccess {
    display: flex;
    align-items: center;
    padding-top: 0.3rem;
    gap: 0.625rem;
    margin-bottom: 1.5rem;
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.68rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: center;
    letter-spacing: 0.04rem;
  }

  .RequestStatusDetails {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .ConciergeWrapperHeading {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-bottom: 1rem;
  }
  .ConciergeWrapperDetail {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.02rem;
  }
  .ConciergeWrapperDetail > span {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .ConciergeWrapperCall {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
    margin-top: 0.75rem;
    cursor: pointer;
  }

  .svgLine {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .ConciergeWrapperCall > div:nth-child(1) {
    display: flex;
    gap: 0.5rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .fandbmenuMobile2 {
    display: none;
  }
  .CustomerFoodMenuHead {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .CustomerFoodMenuHead2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .MapHeading > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .MapHeading > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .MapHeading > h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  /* //invite buttons  */
  .inviteBttn {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .inviteBttn .guestListPopupDesktopButton,
  .inviteBttn .guestInviteButton {
    display: flex;
    width: 48%;
    padding: 0.8125rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;

    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inviteBttn .guestListPopupDesktopButton {
    background: #4a6afe;
    color: white;
  }

  .inviteBttn .guestInviteButton {
    color: #4a6afe;
    border: 1px solid #4a6afe;
  }

  .guestListPopupMobileButton {
    display: none;
  }

  .ConciergeWrapper h6 {
    margin-top: 2.6rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration-line: underline;
  }

  .menu-content {
    width: 100%;
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
    justify-content: space-between;
  }

  .selectInvite {
    width: 20%;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    display: flex;
    padding: 0.90625rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }

  .inviteInput {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    width: 75%;
    padding: 0.75rem 1rem;
    align-items: center;
  }

  .customise {
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 0.88rem;
  }

  .customise textarea {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
    width: 100%;
    min-height: 7.5rem;
    padding: 0.75rem 1rem;

    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
  .customise div {
    display: flex;
    justify-content: end;
  }

  .customise button {
    width: 28%;
    padding: 0.8125rem 2rem;
    border-radius: 0.5rem;
    background: #7e0000;
    color: white;
  }

  /* *********** Invite Form ***************** */

  .inviteForm h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .inviteInnerForm {
    display: flex;
    gap: 2rem;
    width: 100%;
  }

  .inviteInnerForm h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inviteInnerForm span {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
  }

  .inviteInnerForm > div {
    width: 100%;
    margin-top: 2rem;
  }

  .inviteInnerForm input {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0.9375rem 1rem 0.9375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
  }

  .inviteInnerForm h5 {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .inviteInnerForm h5 span {
    color: #183fd4;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration-line: underline;
  }

  .inviteInnerForm textarea {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    width: 100%;
    padding: 1rem;
    min-height: 6.5rem;
  }

  .inviteInnerForm button {
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8125rem 1rem;
    /* background: #7e0000; */
    width: 100%;
    margin: 2.3rem 0rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  /* ***********RIGHT SIDE ***************** */

  .RequestWrapperRight1,
  .RequestWrapperRight5 {
    width: 40%;
    position: sticky;
    top: 8rem;
    height: fit-content;
  }

  .RequestWrapperChild1,
  .RequestWrapperChild5 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    padding: 1.2rem;
  }

  .resCardImgOne {
    width: 100%;
    max-height: 24rem;
    object-fit: cover;
    border-radius: 1rem;
  }

  .everything-wrapper1,
  .everything-wrapper5 {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  .RequestDateAndTimeWrapper1,
  .RequestDateAndTimeWrapper5 {
    align-items: flex-start;
    margin-top: 1rem;
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .RequestSummaryChild1,
  .RequestSummaryChild5 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .RequestSummaryChild21,
  .RequestSummaryChild25 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .RequestSummaryChild3 {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    justify-content: space-between;
    /* border-bottom: 1px solid #e5e5ea; */
  }

  .RequestSummaryChild3 div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .RequestSummaryWrapper {
    display: flex;
    flex-direction: column;
  }

  .RequestSummaryChild4 {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    border-bottom: 1px solid #e5e5ea;
  }

  .RequestSummaryChild4 div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .RequestStatusHeading3 {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem 0rem 0rem;
  }

  .RequestStatusHeading3 div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .navbar-div-res {
    position: sticky;
    top: 0;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.15);
    backdrop-filter: blur(50px);
    padding: 0.9375rem 7rem;
    align-items: center;
    z-index: 10;
  }
  .navbar-div-res .barbaar-logo-res {
    width: 8rem;
    height: 4.375rem;
  }

  .outermenuBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 1.1rem;
    justify-content: center;
    align-items: center;

    margin-top: 1.38rem;
  }

  .menu-box {
    display: flex;
    padding: 0.75rem 0.4rem 0.75rem 0.75rem;
    align-items: center;
    border-radius: 0.5rem;
    background: #eff2ff;
    gap: 0.5rem;
  }

  .menu-box div {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0175rem;
  }

  .menu-popup-background-custom-10 {
    display: flex;
  }
  .menu-content {
    display: flex;
    flex-direction: column;
  }
}

/* mobile css */
@media (min-width: 10px) and (max-width: 640px) {
  .outer-new {
    display: flex;
    flex-direction: column-reverse;
  }

  .RequestSuccess {
    display: flex;
    align-items: start;
    margin-bottom: 1rem;
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.03rem;
  }

  .fandbmenuWeb {
    display: none;
  }

  .ResIDTop {
    display: none !important;
  }

  /* .inside-right, .RequestWrapperChild{
    display: none;
  } */

  .navbar-div-res {
    display: none;
  }

  /* In your CSS file */
  .body-no-scroll {
    overflow: hidden;
  }

  .inner-new1 {
    padding-top: 1.87rem;
    border-top: 1px solid #e5e5ea;
  }

  .inner-new1 > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .inner-new1 > h6 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inner-new1 > h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1.5rem;
  }

  .inner-new1 > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .RequestWrapper {
    width: 100%;
    padding: 1rem;
    min-height: 100vh;
  }

  .RequestWrapperLeft {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    /* border: 2px solid green; */
  }

  .RequestWrapperRight1 {
    display: none;
  }

  .RequestSuccess {
    display: flex;
    align-items:start;
    padding-top: 0.3rem;
    margin-bottom: 1.5rem;
  }

  .confirmTitle {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.03rem;
    padding-left: 1rem;
  }

  .RequestStatusDetails {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .resCardImgOne1 {
    width: 6.875rem;
    height: 6.875rem;
  }

  .ConciergeWrapperHeading {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-bottom: 1.5rem;
  }
  .ConciergeWrapperDetail {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
  .ConciergeWrapperDetail > span {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .ConciergeWrapperCall {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
    margin-top: 0.75rem;
    cursor: pointer;
  }

  .svgLine {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .ConciergeWrapperCall > div:nth-child(1) {
    display: flex;
    gap: 0.5rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .CustomerFoodMenuHead {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-bottom: 1.5rem;
  }

  .MapHeading > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .MapHeading > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .MapHeading > h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .cardMobile {
    border-radius: 1rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    /* box-shadow: 0px -4px 16px 0px rgba(34, 34, 34, 0.08),
      0px 4px 16px 0px rgba(34, 34, 34, 0.08); */
    display: inline-flex;
    padding: 0.8rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
    width: 98%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  .cardMobileChild {
    display: flex;
    gap: 1rem;
  }

  .mobileOutletHeader {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .cardMobileChild h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0175rem;
  }

  .desktopMenuParent {
    display: none;
  }

  .cardMobileParent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.02rem;
  }

  .PackageNamexyz {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .cardMobileParent div,
  .cardMiddle h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .cardMobileBottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e5e5ea;
    width: 100%;
    padding-top: 1.25rem;

    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.02rem;
  }

  .menu-popup-custom2 {
    width: 100%;
    overflow-y: auto;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: #fff;
    padding: 2.5rem 1rem;
    position: relative;
  }

  .navbar-div-res,
  .barbaar-logo-res {
    display: none;
  }

  .inviteBttn {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 1.38rem;
    margin-top: 1.38rem;
    width: 100%;
  }

  .inviteBttn .guestInviteButton,
  .inviteBttn .guestListPopupMobileButton {
    display: flex;
    padding: 0.8125rem 0.46875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.5rem;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inviteBttn .guestListPopupMobileButton {
    background: #4a6afe;
    color: white;
  }

  .inviteBttn .guestInviteButton {
    background: white;
    color: #4a6afe;
    border: 1px solid #4a6afe;
  }

  .guestListPopupDesktopButton {
    display: none;
  }

  .menu-popup-background-custom2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(1px); */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background: rgba(71, 71, 71, 0.5); */
  }

  .desktopMenuParent input[type="checkbox"],
  .desktopMenuParent input[type="radio"],
  .fandbmenuMobile2 input[type="checkbox"],
  .fandbmenuMobile2 input[type="radio"] {
    appearance: none;
    position: relative;
    cursor: pointer;
  }

  .desktopMenuParent input[type="checkbox"]:checked:after,
  .desktopMenuParent input[type="radio"]:checked:after,
  .fandbmenuMobile2 input[type="checkbox"]:checked:after,
  .fandbmenuMobile2 input[type="radio"]:checked:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 2.5px;
    left: 6.5px;
  }

  /* Invitee form  */

  .inviteForm {
    padding-bottom: 5rem;
  }

  .inviteForm h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .inviteInnerForm h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inviteInnerForm span {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.015rem;
  }

  .inviteInnerForm > div {
    width: 100%;
    margin-top: 2rem;
  }

  .inviteInnerForm input {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    display: flex;
    width: 100%;
    height: 3rem;
    padding: 0.9375rem 1rem 0.9375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
  }

  .inviteInnerForm h5 {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* letter-spacing: 0.0175rem; */
    padding-top: 1rem;
  }

  .inviteInnerForm h5 span {
    color: #183fd4;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration-line: underline;
  }

  .inviteInnerForm textarea {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    width: 100%;
    padding: 1rem;
    min-height: 6.5rem;
  }

  .inviteInnerForm button {
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8125rem 1rem;
    /* background: #7e0000; */
    width: 100%;
    margin: 2.3rem 0rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }
}
