/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .PackageInfoMain2 {
    display: flex;
    flex-direction: column;
  }

  .mobile-comp {
    margin-top: 1.87rem;
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    /* overflow-y: auto;  */
    justify-content: space-between;
    /* min-height: 70vh; */
  }

  .inner-mobile-comp {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .inner-mobile-comp > div {
    width: 48%;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 0.75rem; */

    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01625rem;
  }

  .mobile-comp .guest-div {
    margin-top: 1rem;
  }

  .mobile-comp .guest-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .mobile-comp .guest-div > div,
  .selectedPeopleInput {
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.2rem !important;
    border-top-right-radius: 0.5rem;
    /* border: 1px solid #c4c4c4; */
    border: 2px solid red;
    background: #fff;
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .mobile-comp .guest-div {
    border-radius: 0.5rem;
  }

  .selectedPeopleInput::placeholder,
  .Selected-place,
  .Selected-place-Individual {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01375rem;
  }
  .selectedPeopleInput {
    height: 4rem;
    border-radius: 0.5rem !important;
  }

  .PackageInfoInnerRightDT h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .mobile-comp .guest-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .date-time-wrapper {
    display: flex;
    justify-content: space-between;
    height: 4rem;
  }
  .date-time-wrapper > .date-div,
  .date-time-wrapper > .slot-div {
    width: 49.99%;
  }

  .custom-dropdown {
    width: 100%;
  }
  .dropdown-header {
    display: flex;
    padding: 1rem;
    align-items: center;
    height: 4rem;
    border-radius: 0rem 0.5rem 0rem 0rem;
    border: 1px solid #ccc;
    border-bottom: none;
  }
  .dropdown-header.active {
    border: 2px solid #000;
  }
  .dropdown-header > div {
    width: 100%;
  }
  .Time-placeHolder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Time-placeHolder > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .selectedPeopleInput::placeholder,
  .Selected-place,
  .Selected-place-Individual {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01375rem;
  }
  .selectedPeopleInput {
    height: 5rem;
    border-radius: 0.5rem;
  }

  .mobile-comp .Selected-place {
    position: absolute;
    left: 2.2rem;
    top: 24rem;
  }

  .mobile-comp .Selected-place-Individual {
    position: absolute;
    left: 2rem;
    top: 22.95rem;
  }
  .Time-placeholder-child {
    color: #222;
    font-family: Gilroy-Bold;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01375rem;
  }
  .Time-Value {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .dropdown-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem 1.75rem 1.5rem 1.8125rem;
    border: none;
    width: 87.5%;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
    position: absolute;
    right: 1.87rem;
    z-index: 1;
    gap: 0.88rem;
  }

  .dropdown-option {
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  .PackageInfoInnerRightDT > .date-div h2,
  .PackageInfoInnerRightDT > .slot-div h2,
  .mobile-comp .guest-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .selected-timeSlot {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 0.1rem;
    padding-left: 0.5rem;
    width: 80%;
  }

  .time-slot-header {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .selected-timeSlot > div {
    display: flex;
    width: 90%;
    padding: 0.525rem 0.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  .mobile-comp .guest-div > div,
  .selectedPeopleInput {
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.2rem;
    border: 1px solid #c4c4c4;
    background: #fff;
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .mobile-comp .guest-div {
    border-radius: 0rem 0rem 0rem 0.5rem;
  }
  .closure-div {
    width: 100%;
  }

  .slider-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem;
    -webkit-appearance: none;
    appearance: none;
    /* background: transparent; */
    background: #e5e5ea;
    border-radius: 3.125rem;
  }

  .slider-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 13px;
    background: linear-gradient(to right, #4a6afe, #4a6afe) 0% 0% /
        var(--range-progress) 100% no-repeat,
      #e5e5ea; /* Track color */
    border: none;
    border-radius: 3.125rem;
  }

  /* the circle which is dragged */
  .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #4a6afe;
    border: 2px solid #4a6afe;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
  }

  .slider-input:focus::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #4a6afe, #4a6afe) 0% 0% /
        var(--range-progress) 100% no-repeat,
      #e5e5ea; /* Track color when focused */
  }

  .slider-input::-moz-range-track {
    width: 100%;
    height: 13px;
    background: linear-gradient(to right, #4a6afe, #4a6afe) 0% 0% /
        var(--range-progress) 100% no-repeat,
      #e5e5ea; /* Track color */
    border: none;
    border-radius: 3.125rem;
  }

  /* the circle which is dragged */
  .slider-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #4a6afe;
    border: 2px solid #4a6afe;
    border-radius: 50%;
    cursor: pointer;
  }

  .slider-input::-ms-track {
    width: 100%;
    height: 20px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  .slider-input::-ms-fill-lower {
    background: #4a6afe;
    border-radius: 5px;
  }

  .slider-input::-ms-fill-upper {
    background: #e5e5ea;
    border-radius: 5px;
  }
  /* the circle which is dragged */
  .slider-input::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #4a6afe;
    border: 2px solid #4a6afe;
    border-radius: 50%;
    cursor: pointer;
  }

  .slider-input:focus::-ms-fill-lower {
    background: #4a6afe; /* Track color when focused */
  }

  .slider-input:focus::-ms-fill-upper {
    background: #e5e5ea; /* Track color when focused */
  }

  .slider-input:focus + .selected-people,
  .slider-input:active + .selected-people,
  .slider-input:focus + .total-cost,
  .slider-input:active + .total-cost {
    opacity: 1;
  }
  .selected-timeSlot > div.selected {
    background-color: #4a6afe;
    color: #fff;
  }
  .PackageInfoInnerRightDT > .date-div {
    width: 100%;
  }

  /* .priceParentDiv2{
    border-top: 1px solid #E5E5EA!important;
    padding-top: 2rem !important;
    margin-top: 0rem !important;
} */

  .priceParentDiv,
  .priceParentDiv2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .priceParentDiv > div,
  .priceParentDiv2 > div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  .priceParentDiv .DiscountedPrice {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: line-through;
  }
  .priceParentDiv2 .ActualPrice {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .priceParentDiv .ActualPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .priceParentDiv2 .personXpax {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .priceParentDiv .personXpax {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .personXpax > span {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .TotalPrice4 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-parent2,
  .total-cost-parent3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;
  }

  .total-cost-text3,
  .total-cost3 {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .total-cost-text2 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-text2 > span {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .total-cost2 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    border: 1px solid red;
  }

  .slider-wrapper {
    width: 100%;
    position: relative;
    margin: 2.5rem 0rem 0rem 0rem !important;
  }

  .slider-markers {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -5px;
  }

  .slider-markers2 {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 1.23rem;
  }

  .slider-marker {
    position: absolute;
    transform: translateX(-50%);
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .slider-marker2 {
    width: 0.1875rem;
    height: 0.8rem;
    flex-shrink: 0;
    border-radius: 0.125rem;
    background: #0075ff;
    position: absolute;
    transform: translateX(-50%);
    top: -0.2rem;
  }

  .moneySavedDiv {
    width: 100%;
    border-radius: 0.5rem;
    background: #dbe1ff;
    text-align: center;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    margin-top: 1.5rem;
    padding: 0.7rem;
    margin-bottom: 5rem;
  }

  .moneySavedDiv2 {
    width: 100%;
    border-radius: 0.5rem;
    background: #f2e6e6;
    padding: 0.75rem;
    text-align: center;
    color: #7e0000;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    margin-top: 0.6rem;
  }

  .selectedPeopleInput {
    -moz-appearance: textfield;
  }

  .continue-bttn {
    display: flex;
    padding: 0.6rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    width: 100%;
    margin-top: 0.4rem;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.6rem 0.8rem;
    background-color: white;
  }

  .continue-bttn button {
    width: 100%;
    padding: 1.125rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
}
