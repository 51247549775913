@media (min-width: 1024px) {
  .PackageInfoPhone {
    display: none;
  }

  .food-menu-wrapper-parent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .food-menu-wrapper-child {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .read-more-button {
    color: #242426;
    cursor: pointer;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015rem;
  }

  .headerImgPackage {
    width: 100%;
  }
  .PackageInfoMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* padding-top: 2rem; */
    min-height: 55vh;
  }

  .PackageInfoInnerLeft {
    width: 100%;
  }
  .packageNameDiv {
    display: flex;
    gap: 1rem;
  }
  .packageNameDiv > div:nth-child(1) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageNameDiv > div:nth-child(2) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #fcddd6;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageLeftOne {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 1.88rem 8% 2.06rem 8%;
    border-bottom: 1px solid #d8d8dc;
  }
  .packageLeftOne > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
  .packageLeftOne > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .packageLeftTwo {
    position: relative;
    top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
  }

  .special {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .special.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .packageLeftTwo h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .FoodmenuIconPar {
    display: flex;
    position: sticky;
    top: 6.5rem;
  }
  .FoodmenuIconPar2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .FoodmenuIconPar2 svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .vegOnlyToggleDiv {
    display: flex;
    align-items: center;
    justify-self: flex-end;
    gap: 0.5rem;
  }

  .vegOnlyToggleDiv > span {
    color: #000;
    font-family: Gilroy-Bold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .packageLeftTwo > .FoodmenuIconPar {
    display: flex;
    gap: 1rem;
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inside-nonAlcoholic {
    align-items: center;
  }

  .selected-food-list5 {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    padding: 1.25rem 1rem 1.25rem 1.5rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    background-color: #fff;
    display: flex;
    gap: 1.5rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid #e5e5ea;
    border-top: none;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
  }

  .selected-food-list {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    padding: 1.25rem 1rem 1.25rem 1.5rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 1.5rem 0rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid #e5e5ea;
    border-top: none;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
  }

  .selected-food2 {
    display: flex;
    padding: 0.8rem;
    align-items: flex-start;
    gap: 1rem;
    cursor: pointer;
    max-width: 96%;
  }

  .selected-food2.active {
    outline: 2px solid #4a6afe;
    border-radius: 0.5rem;
  }

  .selected-food2:hover,
  .whole-div:hover {
    outline: 2px solid gray;
    border-radius: 0.5rem;
  }

  .selected-food2.active:hover {
    outline: 2px solid #4a6afe;
  }

  .selected-food2:focus {
    outline: none;
  }

  .whole-div {
    display: flex;
    width: 45%;
    gap: 1rem;
    padding: 0.8rem;
  }

  .ItemImageWrapper5 {
    min-width: 20%;
    max-width: 22%;
    min-height: 20%;
    max-height: 22%;
  }

  .ItemImageWrapper {
    min-width: 25%;
    max-width: 26%;
    min-height: 25%;
    max-height: 26%;
  }

  .ItemImageWrapper > img {
    width: 6.5rem;
    height: 6.25rem;
  }

  .ItemDetailWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem 0rem;
  }

  .ItemDetailWrapper2 {
    display: flex;
    flex-direction: column;
    gap: 0.6rem 0rem;
  }

  .ItemDetailName {
    color: #363638;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .ItemDetailDesc {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
  }

  /* / ///////////////////////////////////// / */

  .PackageInfoInnerRight {
    display: flex;
    padding: 1.875rem;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.06),
      0px -4px 16px 0px rgba(34, 34, 34, 0.06);
    width: 42%;
    height: fit-content;
  }

  .PackageInfoInnerRight h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .PackageInfoInnerRight > .date-div,
  .PackageInfoInnerRight > .slot-div,
  .PackageInfoInnerRight > .guest-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .PackageInfoInnerRight > .date-div h2,
  .PackageInfoInnerRight > .slot-div h2,
  .PackageInfoInnerRight > .guest-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .selected-timeSlot {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 0.1rem;
    padding-left: 0.5rem;
    width: 80%;
  }

  .time-slot-header {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .selected-timeSlot > div {
    display: flex;
    width: 90%;
    padding: 0.525rem 0.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  .PackageInfoInnerRight > .guest-div > div,
  .selectedPeopleInput {
    display: flex;
    padding: 1rem 1rem;
    align-items: center;
    border-radius: 0.2rem;
    border: 1px solid #c4c4c4;
    background: #fff;
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .selectedPeopleInput::placeholder {
    color: #242426;
  }

  .closure-div {
    width: 100%;
  }

  .slider-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem;
  }

  .slider-input:focus + .selected-people,
  .slider-input:active + .selected-people,
  .slider-input:focus + .total-cost,
  .slider-input:active + .total-cost {
    opacity: 1;
  }
  .selected-timeSlot > div.selected {
    background-color: #4a6afe;
    color: #fff;
  }
  .PackageInfoInnerRight > .date-div {
    width: 100%;
  }

  .priceParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .priceParentDiv > div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .priceParentDiv .DiscountedPrice {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration: line-through;
  }
  .priceParentDiv .ActualPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .priceParentDiv .personXpax {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .personXpax > span {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .TotalPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .total-cost-text {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .total-cost-text > span {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .total-cost {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .hidden {
    display: none;
  }
  .ReserveButton {
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    width: 100%;
    padding: 0.81rem 0rem;
  }

  .slider-wrapper {
    width: 100%;
    position: relative;
    margin: 1.5rem 0rem 1rem 0rem;
  }

  .slider-markers {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -5px;
  }

  .slider-marker {
    position: absolute;
    transform: translateX(-50%);
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .moneySavedDiv {
    width: 100%;
    border-radius: 0.5rem;
    background: #dbe1ff;
    padding: 0.75rem;
    text-align: center;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    margin-bottom: 0.8rem;
  }

  .ReserveButtondiv {
    width: 100%;
    display: flex;
  }

  .ReserveButtondiv > h4 {
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }
  .ReserveButtondiv span {
    color: var(--Black, #4a6afe);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selectedPeopleInput {
    -moz-appearance: textfield;
  }

  .selectedPeopleInput::-webkit-inner-spin-button,
  .selectedPeopleInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .animate {
    animation: rollUp 0.8s ease-in-out;
  }

  @keyframes rollUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .toggle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0.25em;
    padding: 0.125em;
    font-size: 1.5em;
  }

  .toggle-checkbox {
    appearance: none;
    position: absolute;
    z-index: 1;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    font: inherit;
    opacity: 0;
    cursor: pointer;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0.25rem;
    width: 2.25rem;
    height: 1.125rem;
    background-color: #e8e8e8;
    transition: background-color 0.4s linear;
  }

  .toggle-checkbox:checked + .toggle-container {
    background-color: #009600;
  }

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0.0625em;
    border-radius: 0.2rem;
    width: 0.9rem;
    height: 0.9rem;
    background-color: #fff;
    transition: left 0.4s;
  }

  .toggle-checkbox:checked + .toggle-container > .toggle-button {
    left: 1.2rem;
  }

  .toggle-button-circles-container {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 0.125em;
    position: absolute;
    margin: 0 auto;
  }

  .toggle-button-circle {
    border-radius: 50%;
    width: 0.125em;
    height: 0.125em;
    background-image: radial-gradient(circle at 50% 0, #f5f5f5, #c4c4c4);
  }

  /* footer  */

  .PackageInfofooter,
  .PackageInfofooter2 {
    display: flex;
    width: 100%;
    padding: 0.7rem 0rem 0.5rem 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.625rem;
    border-top: 1px solid #e5e5ea;
    background: #fff;
    position: sticky ;
    bottom: 0rem; 
    z-index: 3;
    top: 0rem;
  }
  .PackageInfofooter .continue-container,
  PackageInfofooter2 .continue-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
  }

  .continuecontainerItemsAdded {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    margin-bottom: 0.75rem;
  }

  .continuecontainerItemsSummary {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .PackageInfofooter button,
  .PackageInfofooter2 button {
    display: flex;
    padding: 0.8125rem 6.5rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .addonDropdown-parent {
    border-radius: 1rem 1rem 0rem 0;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
  }

  .dropdown-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
  }
}

/* mobile css */
@media (min-width: 10px) and (max-width: 640px) {
  .read-more-button {
    color: #242426;
    cursor: pointer;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015rem;
  }

  .strong {
    display: none;
  }

  .left-container ul {
    /* padding-left: 0rem; */
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    width: 100%;
    padding-right: 1.5rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
  }

  .category {
    cursor: pointer;
    color: #767c8f;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: none;
  }

  .category.active {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: underline;
    text-underline-offset: 0.4rem;
  }

  .headerImgPackage {
    width: 100%;
    height: 5rem;
  }
  .PackageInfoMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 55vh;
    /* border: 1px solid red; */
  }

  .PackageInfoInnerLeft {
    width: 100%;
    /* border: 1px solid blue; */
  }
  .packageNameDiv {
    display: flex;
    gap: 1rem;
  }
  .packageNameDiv > div:nth-child(1) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #dbe1ff;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageNameDiv > div:nth-child(2) {
    display: flex;
    padding: 0.375rem 0.5rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    background: #fcddd6;
    color: #ee4622;
    font-family: Gilroy-SemiBold;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.4rem 0rem;
  }
  .packageLeftOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 1.88rem 8% 2.06rem 3.8%;
    border-bottom: 1px solid #d8d8dc;
  }
  .packageLeftOne > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
  .packageLeftOne > p {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .packageLeftTwo {
    position: relative;
    top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    /* padding-right: 1.25rem; */
  }

  .foodMenu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .left-container {
    display: flex;
    overflow-x: auto;
    flex: 0 0 auto;
    position: sticky;
    z-index: 2;
    top: 6.9%;
    left: 0;
    padding-top: 0.7rem;
    background: white;
  }

  .left-container > div {
    text-align: center;
    white-space: nowrap;
    width: auto;
    padding: 0rem 1rem 1rem 1rem;
    box-sizing: border-box;
  }

  .right-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .special {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .special.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .packageLeftTwo h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .FoodmenuIconPar {
    display: flex;
    position: sticky;
    top: 6.5rem;
  }
  .FoodmenuIconPar2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .FoodmenuIconPar2 svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .vegOnlyToggleDiv {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .vegOnlyToggleDiv > span {
    color: #000;
    font-family: Gilroy-Bold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .packageLeftTwo > .FoodmenuIconPar {
    display: flex;
    gap: 1rem;
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inside-nonAlcoholic {
    align-items: center;
  }

  .selected-food-list5 {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    padding: 1.25rem 1rem 1.25rem 1.5rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    background-color: #fff;
    display: flex;
    gap: 1.5rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid #e5e5ea;
    border-top: none;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
  }

  .selected-food-list {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    padding: 1.25rem 1rem 1.25rem 1.5rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 1.5rem 0rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 1px solid #e5e5ea;
    border-top: none;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
  }

  .selected-food2 {
    display: flex;
    padding: 0.8rem;
    align-items: flex-start;
    gap: 1rem;
    cursor: pointer;
    max-width: 96%;
  }

  .selected-food2.active {
    outline: 2px solid #4a6afe;
    border-radius: 0.5rem;
  }

  .selected-food2:hover,
  .whole-div:hover {
    outline: 2px solid gray;
    border-radius: 0.5rem;
  }

  .selected-food2.active:hover {
    outline: 2px solid #4a6afe;
  }

  .selected-food2:focus {
    outline: none;
  }

  .whole-div {
    display: flex;
    width: 45%;
    gap: 1rem;
    padding: 0.8rem;
  }

  .ItemImageWrapper5 {
    min-width: 20%;
    max-width: 22%;
    min-height: 20%;
    max-height: 22%;
  }

  .ItemImageWrapper {
    min-width: 25%;
    max-width: 26%;
    min-height: 25%;
    max-height: 26%;
  }

  .ItemImageWrapper > img {
    width: 6.5rem;
    height: 6.25rem;
  }

  .ItemDetailWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.2rem 0rem;
  }

  .ItemDetailWrapper2 {
    display: flex;
    flex-direction: column;
    gap: 0.6rem 0rem;
  }

  .ItemDetailName {
    color: #363638;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .ItemDetailDesc {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
  }

  /* / ///////////////////////////////////// / */

  .PackageInfoInnerRight {
    display: flex;
    padding: 1.875rem;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.06),
      0px -4px 16px 0px rgba(34, 34, 34, 0.06);
    width: 42%;
    height: fit-content;
  }

  .PackageInfoInnerRight h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .PackageInfoInnerRight > .date-div,
  .PackageInfoInnerRight > .slot-div,
  .PackageInfoInnerRight > .guest-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .PackageInfoInnerRight > .date-div h2,
  .PackageInfoInnerRight > .slot-div h2,
  .PackageInfoInnerRight > .guest-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .selected-timeSlot {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 0.1rem;
    padding-left: 0.5rem;
    width: 80%;
  }

  .time-slot-header {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .selected-timeSlot > div {
    display: flex;
    width: 90%;
    padding: 0.525rem 0.5625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.25rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  .PackageInfoInnerRight > .guest-div > div,
  .selectedPeopleInput {
    display: flex;
    padding: 1rem 1rem;
    align-items: center;
    border-radius: 0.2rem;
    border: 1px solid #c4c4c4;
    background: #fff;
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .selectedPeopleInput::placeholder {
    color: #242426;
  }

  .closure-div {
    width: 100%;
  }

  .slider-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem;
  }

  .slider-input:focus + .selected-people,
  .slider-input:active + .selected-people,
  .slider-input:focus + .total-cost,
  .slider-input:active + .total-cost {
    opacity: 1;
  }
  .selected-timeSlot > div.selected {
    background-color: #4a6afe;
    color: #fff;
  }
  .PackageInfoInnerRight > .date-div {
    width: 100%;
  }

  .priceParentDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .priceParentDiv > div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
  .priceParentDiv .DiscountedPrice {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    text-decoration: line-through;
  }
  .priceParentDiv .ActualPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .priceParentDiv .personXpax {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .personXpax > span {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .TotalPrice {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .total-cost-parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .total-cost-text {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .total-cost-text > span {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
  .total-cost {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .hidden {
    display: none;
  }
  .ReserveButton {
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    width: 100%;
    padding: 0.81rem 0rem;
  }

  .slider-wrapper {
    width: 100%;
    position: relative;
    margin: 1.5rem 0rem 1rem 0rem;
  }

  .slider-markers {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: -5px;
  }

  .slider-marker {
    position: absolute;
    transform: translateX(-50%);
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .moneySavedDiv {
    width: 100%;
    border-radius: 0.5rem;
    background: #dbe1ff;
    padding: 0.75rem;
    text-align: center;
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    animation: rollUp 0.5s ease-in-out;
    margin-bottom: 0.8rem;
  }

  .ReserveButtondiv {
    width: 100%;
    display: flex;
  }

  .ReserveButtondiv > h4 {
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }
  .ReserveButtondiv span {
    color: var(--Black, #4a6afe);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selectedPeopleInput {
    -moz-appearance: textfield;
  }

  .selectedPeopleInput::-webkit-inner-spin-button,
  .selectedPeopleInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .animate {
    animation: rollUp 0.8s ease-in-out;
  }

  @keyframes rollUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0.25em;
    padding: 0.125em;
    font-size: 1.5em;
  }

  .toggle-checkbox {
    appearance: none;
    position: absolute;
    z-index: 1;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    font: inherit;
    opacity: 0;
    cursor: pointer;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0.25rem;
    width: 2.25rem;
    height: 1.125rem;
    background-color: #e8e8e8;
    transition: background-color 0.4s linear;
  }

  .toggle-checkbox:checked + .toggle-container {
    background-color: #009600;
  }

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0.0625em;
    border-radius: 0.2rem;
    width: 0.9rem;
    height: 0.9rem;
    background-color: #fff;
    transition: left 0.4s;
  }

  .toggle-checkbox:checked + .toggle-container > .toggle-button {
    left: 1.2rem;
  }

  .toggle-button-circles-container {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 0.125em;
    position: absolute;
    margin: 0 auto;
  }

  .toggle-button-circle {
    border-radius: 50%;
    width: 0.125em;
    height: 0.125em;
    background-image: radial-gradient(circle at 50% 0, #f5f5f5, #c4c4c4);
  }

  /* footer  */

  .PackageInfofooter,
  .PackageInfofooter2 {
    display: flex;
    width: 100%;
    padding: 0.7rem 1.25rem 0.5rem 2rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.625rem;
    border-top: 1px solid #e5e5ea;
    background: #fff;
    position: sticky !important;
    bottom: 0rem;
    z-index: 3;
    top: 0rem;
  }
  .PackageInfofooter .continue-container,
  PackageInfofooter2 .continue-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .continuecontainerItemsAdded {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    margin-bottom: 0.75rem;
  }

  .continuecontainerItemsSummary {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    letter-spacing: 0.0175rem;
  }

  .PackageInfofooter button {
    display: flex;
    padding: 1.125rem 3.2rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    width: 100%;
    justify-content: center;
  }

  .PackageInfofooter2 button {
    display: flex;
    padding: 1.125rem 3.5rem;
    gap: 0.625rem;
    border-radius: 0.5rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    justify-content: center;
  }

  .category {
    cursor: pointer;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    padding-bottom: 1.5rem;
  }

  .category.active {
    color: #4a6afe;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    text-decoration: underline;
  }

  .FoodCategoryHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 12%;
    z-index: 3;
    padding: 1.5rem 1.25rem 0.5rem 0rem;
    background-color: #fff;
    padding-left: 1rem;
  }

  .vegOnlyToggleDiv {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 0.25em;
    padding: 0.125em;
    font-size: 1.5em;
  }

  .FoodCategoryHeading h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .Food-menu-item-name {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    padding-top: 0.5rem;
  }

  .Food-menu-item-desc {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.015rem;
    padding-top: 0.75rem;
  }

  .food-menu-picture {
    min-width: 8rem;
    min-height: 8rem;
    max-width: 8rem;
    max-height: 8rem;
  }

  .right-container {
    width: 85%;
  }

  .FoodMenu-child {
    display: flex;
    padding: 1.9rem 1.25rem 1.9rem 2rem;
    gap: 0rem 1.25rem;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5ea;
  }

  .food-menu-picture-container {
    position: relative;
    z-index: 1;
  }

  .button-container {
    position: absolute;
    top: 84%;
    left: 50%;
    transform: translateX(-50%);
  }

  .button-select {
    display: flex;
    width: 5.375rem;
    padding: 0.625rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(34, 34, 34, 0.08);
    /* color: #7e0000; */
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    border: 1px solid #bf8080;
  }

  .button-selected {
    display: inline-flex;
    padding: 0.625rem 0rem;
    width: 5.375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    box-shadow: 0px 4px 8px 0px rgba(34, 34, 34, 0.08);
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .food-menu-category,
  .beverage-menu-category-res {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .beverage-menu-category-res.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    text-decoration: none;
  }

  .food-menu-category.active {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .food-menu-category,
  .beverage-menu-category-res {
    display: none;
  }
  .addonDropdown-parent {
    border-radius: 1rem 1rem 0rem 0;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.06);
    width: 90%;
    margin: 0 auto;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100vh; */
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 40;
  }

  .overflow-hidden {
    overflow: hidden;
  }
  
}
