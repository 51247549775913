/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .switchParent {
    width: 95%;
    /* margin: 0 auto; */
    justify-content: center;
    display: flex;
    position: relative;
    margin-left: 4.5rem;
    justify-content: space-between;
    min-height: 78vh;
  }

  .leftSwitch {
    width: 50%;
    padding: 1.3rem;
  }

  .rightSwitch {
    width: 50%;
    /* margin-top: 1.3rem; */
  }

  .sticky-right {
    position: sticky;
    top: 6rem;
    overflow-y: auto;
  }

  .searchButton {
    width: 95%;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    position: sticky;
    top: 5.5rem;
    z-index: 1;
    background-color: #fff;
  }

  .outletsMultiple {
    margin-top: 1.25rem;
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .outletsMultiple div {
    border-radius: 1rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    padding: 1rem;
  }

  .outletsMultiple div img {
    width: 100%;
    border-radius: 0.5rem;
  }

  .outletsMultiple div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1rem;
  }

  .outletsMultiple div h3 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .outletsMultiple div h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 640px) {
  .switchParent {
    width: 100%;
  }
  .footer-hidden {
    display: none;
  }

  .leftSwitch {
    width: 100%;
    padding: 1rem;
  }

  .rightSwitch {
    display: none;
  }

  .searchButton {
    width: 98%;
    padding-bottom: 0.5rem;
    /* border: 1px solid #AEAEB2; */
    margin-bottom: 1rem;
    position: sticky;
    top: 2.5rem;
    z-index: 10;
    background-color: white;
  }

  .outletsMultiple {
    margin-top: 1.25rem;
    width: 98%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .outletsMultiple div {
    border-radius: 0.5rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    padding: 1rem;
  }

  .outletsMultiple div img {
    width: 100%;
    border-radius: 0.5rem;
  }

  .outletsMultiple div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1rem;
  }

  .outletsMultiple div h3 {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .outletsMultiple div h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }
}
