@media (min-width: 1024px) {
.carouselStyle,
.imageStyle{
  width: 100%;
  height: 90vh;
}
.carouselStyle2{
  display: none;
}
.reserveNowCarousel{
  display: none;
}
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .carouselStyle2,
.imageStyle2{
  width: 100%;
  height: 90vh;
  position: relative;
}
.carouselStyle{
  display: none;
}
.carousel-control-prev,
.carousel-control-next,
.carouselStyle2 .carousel-indicators {
  display: none;
}

.reserveNowCarousel {
  width: 8.5rem;
  height: 3rem;
  position: absolute;
  border-radius: 6.25rem;
  color: #fff;
  font-family: Gilroy-SemiBold;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  /* background-color: #7e0000; */
  outline: none;
  overflow: hidden;
  transition: color 0.8s 0.1s ease-out, border 0.8s 0.1s ease-out,
    transform 0.8s 0.1s ease-out;
  bottom: 5%; 
    left: 50%; 
  transform: translateX(-50%);

}
}


