/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .signInCard {
    display: flex;
    padding: 1.875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.25rem;
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px -8px 16px 0px rgba(34, 34, 34, 0.06),
      0px 8px 16px 0px rgba(34, 34, 34, 0.06);
  }

  .signInCard2 {
    display: flex;
    padding: 1.875rem;
    flex-direction: column;
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    width: 25rem;
    box-shadow: 0px -8px 16px 0px rgba(34, 34, 34, 0.06),
      0px 8px 16px 0px rgba(34, 34, 34, 0.06);
  }

  .signInText {
    color: #121212;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .enterText {
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phoneInputSignIn {
    display: flex;
    width: 100%;
    padding: 0.75rem 2.0625rem 0.75rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;

    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contBttnSignIn {
    display: flex;
    width: 100%;
    padding: 0.875rem 2rem 0.875rem 1rem;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #d8d8dc;
    margin-top: 4rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contBttnSignInActive {
    background: #4a6afe;
  }

  .belowSignIn {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
  }

  .enterText span:nth-child(2) {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }

  .enterText span:nth-child(3) {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    padding-left: 1rem;
    cursor: pointer;
  }

  .belowSignIn span,
  h3 {
    color: #3976e8;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h3 {
    margin-top: 1.5rem;
  }

  .otpResend {
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .otpResend span {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .timer {
    margin-left: 4rem;
    cursor: pointer;
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* /////// create page///////// */

  .nameInput {
    display: flex;
    width: 100%;
    padding: 0.8125rem 2rem 0.8125rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;

    border-radius: 0.5rem;
    border: 1px solid #242426;
    background: #fff;
  }

  input::placeholder {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nameInput:nth-child(2) {
    border: 2px solid green;
  }

  .dateInput {
    display: flex;
    width: 100%;
    padding: 0.8125rem 2rem 0.8125rem 1rem;
    flex-direction: column;
    gap: 0.625rem;

    border-radius: 0.5rem;
    border: 1px solid #242426;
    background: #fff;
  }
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .signInCard {
    display: flex;
    padding: 1.75rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px -8px 16px 0px rgba(34, 34, 34, 0.06),
      0px 8px 16px 0px rgba(34, 34, 34, 0.06);

    margin: 1.2rem;
  }

  .signInText {
    color: #121212;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .enterText {
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .phoneInputSignIn {
    display: flex;
    width: 100%;
    padding: 0.75rem 2.0625rem 0.75rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;

    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contBttnSignIn {
    display: flex;
    width: 100%;
    padding: 0.875rem 2rem 0.875rem 1rem;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #d8d8dc;
    margin-top: 2rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contBttnSignInActive {
    background: #4a6afe;
  }

  .belowSignIn {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
  }

  .enterText span:nth-child(2) {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
  }

  .enterText span:nth-child(3) {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    padding-left: 1rem;
    cursor: pointer;
  }

  .belowSignIn span,
  h3 {
    color: #3976e8;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h3 {
    margin-top: 1.5rem;
  }

  .otpResend {
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .otpResend span {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .timer {
    margin-left: 2rem;
    cursor: pointer;
    color: #6c6c70;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* /////// create page///////// */

  .signInCard2 {
    display: flex;
    padding: 1.75rem 1rem;
    flex-direction: column;
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    box-shadow: 0px -8px 16px 0px rgba(34, 34, 34, 0.06),
      0px 8px 16px 0px rgba(34, 34, 34, 0.06);
    margin: 1.2rem;
  }

  .nameInput {
    display: flex;
    width: 100%;
    padding: 0.8125rem 2rem 0.8125rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;

    border-radius: 0.5rem;
    border: 1px solid #242426;
    background: #fff;
  }

  input::placeholder {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .nameInput:nth-child(2) {
    border: 2px solid green;
  }

  .dateInput {
    display: flex;
    width: 100%;
    padding: 0.8125rem 2rem 0.8125rem 1rem;
    flex-direction: column;
    gap: 0.625rem;

    border-radius: 0.5rem;
    border: 1px solid #242426;
    background: #fff;
  }
}
