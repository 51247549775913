@media (min-width: 1024px) {
  .things {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    padding: 3rem 0;
    margin-bottom: 2rem;
  }
  .outer-flex-div {
    display: flex;
    gap: 1.5rem;
  }
  .RulesForDesktop {
    display: flex;
    border-top: 1px solid #E5E5EA;
    width: 82%;
    margin: 0 auto;
  }
  .things > h2,
  .outer-flex-div h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .rules {
    width: 50%;
  }

  .requirements {
    width: 50%;

  }

  .requirements > h2,
  .rules > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .div-1 {
    display: flex;
    /* gap: 4rem 0rem; */
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    align-items: flex-start;

    /* border: 1px solid red; */
  }

  .div-1 > div:nth-child(2) {
    /* width: 100%; */
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }


  .RulesForPhone {
    display: none;
  }
}


@media (min-width: 10px) and (max-width: 1023px) {
  .RulesForDesktop {
    display: none;
  }
  .RulesForPhone {
    width: 92%;
    margin: 0 auto;
    border-top: 1px solid #d8d8dc;
  }
  .ReqPhone,
  .RulePhone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0rem 0.2rem 0rem;
  }
  .RulePhone {
    border-top: 1px solid #d8d8dc;
    padding: 1rem 0rem 1rem 0rem;
  }
  .ReqPhone > div > h3,
  .RulePhone > div > h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
  .ReqPhone > div > p,
  .RulePhone > div > p {
    color: black;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01875rem;
  }
  .ReqPhone > div:nth-child(1),
  .RulePhone > div:nth-child(1) {
    width: 85%;
  }
  .ReqPhone > div:nth-child(2),
  .RulePhone > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
  .Req-popup-background-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background: rgba(71, 71, 71, 0.5); */
    background: rgba(0, 0, 0, 0.40);
  }

  .Req-popup-custom {
    width: 100%;
    height: 70vh;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: #fff;
    padding: 1.25rem;
    position: relative;
    overflow-y: auto;
  }

  .ReqPopupCloseBtn {
    padding: 0.5rem 0rem 1.5rem 0rem;
  }

  .rules,
  .requirements {
    width: 100%;
  }

  .requirements > h2,
  .rules > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
  .div-1 {
    display: flex;
    gap: 4rem 1rem;
    width: 95%;
    margin-top: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .outer-flex-div {
    display: flex;
    justify-content: space-between;
  }

  .div-1 > div:nth-child(2) {
    width: 95%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
}
