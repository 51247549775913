/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .footer-div {
    display: flex;
    width: 100%;
    height: 10vh;
    padding: 1rem 7.3%;
    justify-content: space-between;
    align-items: center;
    background: #fafafa;
  }

  .footer-div h2{
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
  .powered-by h3 {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; 
  }

  .powered-by {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    align-items: flex-end;
  }

  .powered-by img {
    width: 6.875rem;
    height: 2.25rem;
    margin-bottom: 0.5rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */

@media (min-width: 10px) and (max-width: 640px) {
  .footer-div {
    display: flex;
    width: 100%;
    padding: 1rem 2rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    background: #F8F8F8;
    /* border: 2px solid red; */
  }

  .footer-div h2,
  .powered-by h3 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    margin: 0;
  }

  .powered-by {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }

  .powered-by img {
    width: 6rem;
  }
}
