/************************** Full screen ********************** */

@media (min-width: 1024px) {
  .profile-div {
    min-height: 68vh;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 2rem;
    width: 85%;
    gap: 4rem;
  }

  /* **************** form **************** */

  .profile-form {
    display: flex;
    padding: 2rem;
    width: 65%;
    background: #fff;
  }

  .reservationFormAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    /* align-items: center; */
  }

  .reservationFormAccount > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.04rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .reservationFormAccount > h4 {
    color: #1c1c1c;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  label {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .NameDivForm2 {
    display: flex;
    gap: 2.5rem;
    width: 100%;
  }
  .NameDivForm2 > div {
    display: flex;
    width: 47%;
    gap: 0.6rem;
    flex-direction: column;
  }

  .reservationFormAccount input {
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    padding: 0.9rem 1rem;
  }
  .react-tel-input .form-control {
    border: 1px solid #aeaeb2 !important;
  }

  /*  submit bttn  */

  .ConfirmButtondiv2 {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: end;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }

  .submit-btn {
    display: flex;
    width: 100%;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    /* background: #d3b37a; */
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  /************************** left side  ********************************/

  .leftDiv {
    height: 16.1rem;
    width: 38%;
    border-radius: 0.75rem;
    border: 1px solid #e0e0e0;
  }

  .insideLeft {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 0rem !important;
  }

  .selectedDiv {
    display: flex;
    padding: 0.75rem 0rem 0.75rem 1.5rem;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
  }

  .currentItem {
    background-color: #edf0ff;
    color: #4a6afe;
  }

  .defaultItem {
    color: black;
  }

  .defaultItem:hover {
    background-color: #edf0ff;
    color: #4a6afe;
  }

  .iconContainer {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .defaultIconContainer {
    background-color: #e0e0e0;
  }

  .currentIconContainer {
    background-color: #4a6afe;
  }

  .defaultItem:hover .defaultIconContainer {
    background-color: #4a6afe;
  }

  .icon {
    height: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
  }

  .currentIcon {
    color: white;
  }

  .defaultIcon {
    color: gray;
  }

  .itemContainer:hover .defaultIcon {
    color: white;
  }

  /* Specific border radii for the first and last items */
  .firstItem {
    border-radius: 0.75rem 0.75rem 0 0;
  }

  .lastItem {
    border-radius: 0 0 0.75rem 0.75rem;
  }

  /* Hover effect with specific border radii */
  .firstItemHover:hover {
    border-radius: 0.75rem 0.75rem 0 0;
  }

  .lastItemHover:hover {
    border-radius: 0 0 0.75rem 0.75rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .leftDiv {
    display: none;
  }

  .profile-div {
    display: flex;
    width: 100%;
  }

  /* **************** form **************** */

  .profile-form {
    width: 100%;
  }

  .reservationFormAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem;

    position: relative;
    min-height: 90vh;
    padding-bottom: 5rem;
  }

  .reservationFormAccount > h1 {
    display: none;
  }

  .reservationFormAccount > h4 {
    color: #1c1c1c;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-bottom: 1.5rem;
  }

  label {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .NameDivForm2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .NameDivForm2 > div {
    display: flex;
    width: 100%;
    gap: 0.75rem;
    flex-direction: column;
  }

  .reservationFormAccount input {
    width: 100%;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    padding: 1rem 1rem;
  }

  /*  submit bttn  */

  .ConfirmButtondiv2 {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.625rem 1.25rem;
    border-top: 1px solid #d8d8dc;
    background: #fff;
  }

  .submit-btn {
    display: flex;
    width: 100%;
    padding: 0.9rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    /* background: #d3b37a; */
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .mobile-personal-div {
    border: 2px solid green;
  }

  .react-tel-input .form-control {
    border: 1px solid #aeaeb2 !important;
  }
}
