.react-datepicker-wrapper {
  width: 100%;
  border-radius: 5px;
}
.custom-datepicker-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 4rem;
  padding: 1rem;
  border-radius: 0.5rem 0rem 0rem 0rem;
  border-bottom: none;
}

.react-datepicker-popper {
  width: 91%;
  border: none;
  height: 22.6rem;
}

.dateHeading {
  color: #222;
  font-family: Gilroy-Bold;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01375rem;
}
.DateSelectedValue {
  color: #242426;
  font-family: Gilroy-Regular;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0175rem;
}

.custom-calendar {
  display: flex;
  padding: 1.5rem 1.75rem 1.5rem 1.8125rem;
  flex-direction: column;
  border: none;
  height: fit-content;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  width: 96.5%;
  position: absolute;
  left: 7.3rem;
  top: -0.6rem;
}

.react-datepicker__navigation--next {
  position: absolute;
  top: 1.8rem;
  left: 90%;
  color: #222;
}
.react-datepicker__navigation--previous {
  position: absolute;
  top: 1.8rem;
  right: 85%;
  color: #222;
}

.react-datepicker__navigation-icon--next::before,
.react-datepicker__navigation-icon--previous::before {
  content: none;
}

.react-datepicker__header {
  background: #fff;
  border-bottom: none;
}

.custom-header-month-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-current-month {
  color: #222;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0225rem;
}

.react-datepicker__day-names {
  display: none;
}

.custom-day-names {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0.7rem 0.5rem 1rem;
}

.react-datepicker__month {
  width: 100%;
}
.custom-day-name {
  color: #7c7c80;
  text-align: center;
  font-family: Gilroy-SemiBold;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.react-datepicker__week {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.custom-calendar .react-datepicker__day,
.react-datepicker__day--keyboard-selected,
.custom-day {
  border-radius: 6.25rem;
  color: #000;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar .react-datepicker__day--today {
  border: 2px solid #4a6afe !important;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
}

.custom-calendar .react-datepicker__day--disabled {
  border-radius: 6.25rem;
  text-decoration-line: line-through;
  color: #d1d5db;
  text-align: center;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.25rem 0.5rem;
  line-height: 1.25rem;
}
.custom-calendar .react-datepicker__day--disabled:hover {
  color: #d1d5db !important;
  border: none !important;
}
.custom-calendar .react-datepicker__day--selected {
  border-radius: 6.25rem;
  background: #4a6afe;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  padding: 0.25rem 0.5rem;
}

.custom-calendar .react-datepicker__day:hover {
  border: 2px solid #4a6afe;
  text-align: center;
  background-color: #fff;
  color: #000;
  font-family: Gilroy-Medium;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
}

.react-datepicker__triangle {
  display: none;
}

.custom-datepicker-input.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 2px solid #000;
}

.chevron-icon {
  width: 1em;
  height: 1em;
}

.chevron-icon.up {
  transform: rotate(180deg);
}
