/************************ Full screen ******************** */
@media (min-width: 1024px) {
  .carousel2 {
    display: inline-flex;
    width: 100%;
    padding: 4rem 0rem 5rem 0rem;
    flex-direction: column;
    align-items: center;
  }

  .carousel2 h1 {
    color: #121212;
    text-align: center;
    font-family: Gilroy-Bold;
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0625rem;
    width: 55%;
  }

  .carousel2 span {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 2.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0625rem;
  }

  .mobile-scroll {
    position: relative;
    padding: 3rem 0rem;
    display: flex;
    width: 100%;
  }
  .scroll-full-screen-wrapper {
    width: 50%;
  }

  .scroll-full-screen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5rem;
  }

  .mobile-mockup-wrapper {
    width: 50%;
    padding: 0 0 0 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .mobile-mockup {
    position: sticky;
    top: 200px;
    transform: translateX(30%);
    width: 65%;
    height: 100%;
    border-radius: 1rem;
    max-height: 80vh;
  }

  .mobile-mockup-screen {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: auto;
    scroll-behavior: smooth;
  }

  .mobile-screen-img {
    height: 100%;
    border-radius: 1rem;
    width: 100%;
  }

  .screen-text {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .text-visible {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .screen-headingMain {
    color: #4b68fe;
    font-family: Gilroy-Bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-bottom: 1rem;
  }

  .screen-heading {
    color: #000;
    font-family: Gilroy-Bold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
    margin-bottom: 2rem;
  }

  .screen-description {
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    letter-spacing: 0.0225rem;
    margin-bottom: 4rem;
    width: 60%;
  }

  .slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
    }
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .carousel2 {
    display: flex;
    width: 100%;
    padding: 3.75rem 0rem 2rem 0rem;
    flex-direction: column;
  }

  .carousel2 h1 {
    color: #121212;
    text-align: center;
    font-family: Gilroy-Bold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0425rem;
    width: 80%;
    margin: 0 auto;
  }

  .carousel2 span {
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0425rem;
  }

  .CarasuselForMobile {
    width: 85%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 3.75rem;
  }

  .caruselItem20 {
    padding: 0rem 0.5rem;
  }

  .CarasuselForMobileItem {
    width: 100%;
    border-radius: 1rem;
    height: 35vh;
    object-fit: cover;
    /* border: 2px solid red; */
  }

  .carouselMobileHead {
    padding-top: 2rem;
    color: #4a6afe;
    font-family: Gilroy-Bold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .carouselMobileSubHead {
    padding-top: 1.5rem;
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .carouselMobilePara {
    padding-top: 2rem;
    color: #636366;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .carousel-indicators {
    position: absolute;
    left: 2.5%;
    bottom: -10%;
    transform: translateX(-50%);
  }

  .carousel-indicators li {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    text-indent: -999px;
    cursor: pointer;
    border-radius: 50%;
  }

  .carousel-indicators>button{
    background-color: rgb(0, 0, 0) !important;

  }
  .carousel-indicators .active {
    background-color: #4a6afe !important;
  }

  .carouselMobileResButton{
    padding-top: 1.5rem;
  }

  /* Button styling */
  .reserveNowMobile {
    width: 8.5rem;
    height: 3rem;
    border-radius: 6.25rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background: #4a6afe;
    outline: none;
    transition: color 0.8s 0.1s ease-out, border 0.8s 0.1s ease-out,
      transform 0.8s 0.1s ease-out;
  }
}
