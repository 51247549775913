/* mobile css */
@media (min-width: 10px) and (max-width: 640px) {
  .mobile-none-div2 {
    /* min-height: 85vh; */
    /* padding: 0rem 1rem; */
    min-height: 90vh;
  }

  .reservationForm2,
  .reservationForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9rem;
    /* padding-top: 1rem; */
  }

  .reservationForm {
    flex-grow: 1;
    margin-bottom: 2rem;
  }

  .reservationForm2 > h1 {
    display: none;
  }

  .reservationForm2 input,
  .reservationForm input {
    width: 100%;
    height: 3rem;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    padding: 0.5rem 1rem;
    outline: none;
  }

  .reservationForm2 input:focus,
  .reservationForm input:focus {
    background-color: transparent;
    border: 2px solid black;
  }

  .reservationForm2 input:-webkit-autofill,
  .reservationForm2 input:-webkit-autofill:hover,
  .reservationForm2 input:-webkit-autofill:focus,
  .reservationForm2 input:-webkit-autofill:active,
  .reservationForm input:-webkit-autofill,
  .reservationForm input:-webkit-autofill:hover,
  .reservationForm input:-webkit-autofill:focus,
  .reservationForm input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }

  .reservationInput4,
  .reservationInput3 {
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #a6a6a6;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
    padding: 1rem;
    resize: vertical;
    min-height: 7rem;
  }

  /* / confirm button  / */

  .ConfirmButtondiv {
    width: 100%;
    display: flex;
    padding: 0.625rem 1.25rem;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #d8d8dc;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
  }

  .reservationForm2 > h4 {
    display: flex;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .reservationForm2 a {
    color: #183fd4;
    font-family: Gilroy-SemiBold;
    text-decoration: underline;
    font-size: 0.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ConfirmButtondiv > button {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    padding: 0.875rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .nameWrapper,
  .lastname-wrapper,
  .firstname-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
  }
}
