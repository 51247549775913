/************************* Full screen ********************* */

@media (min-width: 1024px) {
  .mobile-cnfrm {
    display: none;
  }

  .CouponBox {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
  }

  .newReservation {
    min-height: 90vh;
    margin: 3rem 6rem;
  }

  .newReservation > h1 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
  }

  .outer-new {
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
  }

  .RequestSummaryChildCusine {
    display: none;
  }

  .inner-new1 {
    width: 45%;
    padding-bottom: 3rem;
  }

  .inner-new1 > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .inner-new1 > h6 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inner-new1 > h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1.5rem;
  }

  .inner-new1 > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .date-infodiv {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .date-infodiv div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .date-infodiv div > h5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .food-menu-div {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .food-menu-div > h4 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .reservationForm {
    width: 100%;
    display: flex;
    padding-top: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.9rem;
    background: #fff;
    /* margin-top: 2rem; */
    /* border-top: 1px solid #e5e5ea; */
  }

  .reservationForm > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .nameWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .firstname-wrapper,
  .lastname-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 48%;
  }

  label {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .reservationForm input {
    width: 100%;
    height: 3rem;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    padding: 0.5rem 1rem;
    outline: none;
  }

  .reservationForm input:focus {
    background-color: transparent;
    border: 2px solid black;
  }

  .reservationForm input:-webkit-autofill,
  .reservationForm input:-webkit-autofill:hover,
  .reservationForm input:-webkit-autofill:focus,
  .reservationForm input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
  }

  .reservationInput3 {
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #a6a6a6;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
    padding: 1rem;
    resize: vertical;
    min-height: 7rem;
  }

  /* / confirm button  / */

  .ConfirmButtondiv {
    width: 100%;
    display: flex;
    padding: 1.5rem 0rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .ConfirmButtondiv > h4 {
    display: flex;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ConfirmButtondiv a {
    color: #183fd4;
    font-family: Gilroy-SemiBold;
    text-decoration: underline;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ConfirmButtondiv > button {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    padding: 0.875rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    /* background: #7e0000; */
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .ConfirmButtondiv > button:hover {
    background: #6f0101;
  }

  /* / right side div / */

  .inner-new2 {
    border-radius: 1rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    position: sticky;
    left: 60%;
    top: 17%;
    height: fit-content;
    width: 35%;
    padding: 1.5rem;
  }

  .inside-right {
    display: flex;
    width: 100%;
    gap: 1rem;
    border-bottom: 1px solid #e5e5ea;
    padding-bottom: 1rem;
  }

  .text-rightwrapper .packageNameDiv2 {
    display: none;
  }

  .inside-right img {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 0.5rem;
  }

  .text-rightwrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .text-rightwrapperH2 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .text-rightwrapperP {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }

  .outer-textwrapper {
    display: flex;
    gap: 1rem;
  }

  .outer-textwrapper > div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }

  .outside-right {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    padding: 0.5rem 0rem;
    border-bottom: 1px dashed #d8d8dc;
  }

  .outside-right h2 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .priceRight {
    display: flex;
    justify-content: space-between;
  }

  .priceRight div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .priceRight-color {
    /* color: #4a6afe !important; */
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .total-price {
    color: #242426;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: Gilroy-SemiBold !important;
    font-size: 1.125rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .dropdownMenuWrapper {
    height: 50vh;
    overflow-y: auto;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  }

  .menuCategoryHeading {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    border-bottom: 1px solid #e5e5ea;
    display: flex;
    padding: 1rem 0rem 1rem 1rem;
    align-items: center;
    gap: 0.625rem;
  }
  .menuItemNameWrapper {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    gap: 0.625rem;
  }
  .menuItemNameWrapper > div {
    color: #363638;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .dropdownMenuWrapper::-webkit-scrollbar {
    width: 0.5rem;
  }

  .dropdownMenuWrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  .dropdownMenuWrapper::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
  }

  .dropdownMenuWrapper {
    scrollbar-width: thin;
  }

  .food-popup-background-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .food-popup-custom {
    width: 30%;
    height: 20rem;
    border-radius: 0.5rem;
    background: #fff;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url("https://s3-alpha-sig.figma.com/img/4b0b/2681/490fb23aef95818a192fcc895a35e65e?Expires=1707696000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Tgdy2J75zK2HBcjgA4GVSLLXkYkPRGbL61aRKYy5a~ri0cYahtRUSVJLy0bScsI9MfqRuAaix36uKJMRowogSNRkd7Q4B4oGInN114LgI49kCoIMbjpAIt3gIiNJEY7U-zYuFU0r-yEyPBMHEVgNeUbGYlMQVM0bgPg-4-IKtM-TS6Nc2kqV0BS-kjgXOOtC87rJPkX0q41ELOBbPmxXfnUUmZlUQHkFWcYBSwInMeBlgXZrZq2NaIrR8lxr21ev1agS~Zn9OXDSKfrwvmtLwWXh9dNmmeL3v3ZJlSK5ZEgejhENDV14~DZSImSL3ohWEbdrfA3ovIcMHHHzori2tw__"),
      white 50% / cover repeat;
  }

  .inner-custom {
    width: 68%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .food-popup-custom h3 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
  }

  .food-popup-custom h1 {
    color: #5b5b5b;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foodMenuDropdownButton {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
  }

  .foodMenuDropdownButton > span {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .moneySavedDivReservation {
    width: 100%;
    border-radius: 0.5rem;
    background: #dbe1ff;
    text-align: center;
    color: #4a6afe;
    padding: 0.75rem;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  .fandbmenuMobile {
    display: none;
  }
  .phoneIndividualDateAndTIme {
    display: none;
  }
}

/* mobile css */
@media (min-width: 10px) and (max-width: 640px) {
  .mobile-none-div {
    display: none;
  }

  .ConfirmButtondiv > h4 {
    display: flex;
    color: var(--Black, #242426);
    font-family: Gilroy-Medium;
    font-size: 0.61rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .CouponBox {
    border-radius: 0.5rem;
    border: 1px solid #aeaeb2;
    background: #fff;
  }

  /* .footer-div{
    display: none;
  } */

  .newReservation {
    margin: 1.25rem;
    margin-top: 0;
  }

  .newReservation > h1 {
    display: none;
  }

  .outer-new {
    display: flex;
    flex-direction: column-reverse;
    min-height: 100vh;
    justify-content: start;
    /* position: absolute; */
  }

  .inner-new1 {
    padding-top: 1.87rem;
    border-top: 1px solid #e5e5ea;
  }

  .inner-new1 > h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .inner-new1 > h6 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inner-new1 > h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    margin-top: 1.5rem;
  }

  .inner-new1 > p {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .date-infodiv > div {
    display: flex;
    gap: 2rem;
  }

  .date-infodiv > div > div:nth-child(1) {
    width: 30%;
  }

  .date-infodiv > div > div > p {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .date-infodiv > div > div > h5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inner-new2 h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .inner-new2 p {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0175rem;
  }

  .inside-right,
  .RequestWrapperChild,
  .RequestWrapperChild5 {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 1.5rem;
  }

  .text-rightwrapper,
  .everything-wrapper,
  .everything-wrapper5 {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
  .text-rightwrapperH2,
  .RequestDateAndTimeWrapper,
  .RequestDateAndTimeWrapper5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .text-rightwrapperP,
  .RequestSummaryChild,
  .RequestSummaryChild5,
  .RequestSummaryChildCusine,
  .RequestSummaryChildCusine5 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }

  .resCardImgOne {
    width: 6.875rem;
    height: 6.875rem;
  }

  .outer-textwrapper {
    display: flex;
    gap: 0.62rem;
  }

  .outer-textwrapper > div {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }

  .inside-right img {
    width: 7.5rem;
    height: 8.3rem;
    border-radius: 0.5rem;
  }

  .outside-right,
  .priceRight,
  .moneySavedDivReservation {
    display: none;
  }

  .newReservation .inner-new1 .packageNameDiv2 {
    display: none;
  }
  .AllCapsFlexWrapper {
    display: flex;
    justify-content: space-between;
  }

  .AllCapsFlexWrapper > h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .AllCapsFlexWrapper > p {
    color: #242426;

    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .menu-popup-background-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: rgba(71, 71, 71, 0.5);
  }

  .menu-popup-custom {
    width: 100%;
    height: 65vh;
    overflow-y: auto;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: #fff;
    padding: 2rem 1rem;
    position: relative;
  }

  .foodmenuHeadertwo {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    padding-top: 1rem;
  }

  .MenuPopupCloseBtn {
    padding: 0rem 0rem 1rem 0rem;
    /* border: 2px solid red; */
  }

  .headingPrice {
    color: #000;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .fandbmenuDesktop {
    display: none;
  }

  .mobile-cnfrm {
    display: flex;
    padding: 0.625rem 0.1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-top: 1px solid #d8d8dc;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .mobile-cnfrm button {
    display: flex;
    padding: 1.125rem 2rem;
    width: 95%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #7e0000;

    color: #fff;
    text-decoration: none;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .RequestSummaryChild2,
  .RequestSummaryChild3 {
    display: none;
  }

  .phoneIndividualDateAndTIme {
    height: 63vh;
    border-top: 1px solid #e5e5ea;
    padding-top: 1.87rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .reservationForm > h1 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    padding-top: 1.5rem;
  }
}
