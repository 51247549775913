/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .accountHeading {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.04rem;
  }

  .acccountSubHeading {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0225rem;
    margin-top: -1rem;
  }

  .accountBox {
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    display: flex;
    padding: 1rem 1.38rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .accountName {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-top: 1.5rem;
  }

  .accountDesc {
    color: #1c1c1c;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
}

/* / / ***************** tablet Screen *********************  / */
@media (min-width: 641px) and (max-width: 1023px) {
}

/******************** Mobile view ************ */
@media (min-width: 10px) and (max-width: 640px) {
  .accountHeading {
    display: none;
  }

  .acccountSubHeading {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.0225rem;
  }

  .accountBox {
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
    background: #fff;
    display: flex;
    padding: 1rem 1.38rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .accountName {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-top: 1.5rem;
  }

  .accountDesc {
    color: #1c1c1c;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
}
